export * from './tokenData.queries';
export * from './topTokens.queries';

export const TOKENS_DATA = [
  {
    address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    name: 'Ether',
    symbol: 'ETH',
    volumeUSD: 7242601421.148621,
    tvlUSD: 1342545070.1807587,
    priceUSD: 2563.169764532884,
    priceUSDChange: -4.762799987753813,
    priceUSDChangeWeek: -7.3762535448706394,
  },
  {
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    name: 'USD Coin',
    symbol: 'USDC',
    volumeUSD: 5932417085.993652,
    tvlUSD: 756629996.810815,
    priceUSD: 0.9262374645512935,
    priceUSDChange: -7.872677670650831,
    priceUSDChangeWeek: -7.376253544870639,
  },
  {
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    volumeUSD: 1227987835.5801773,
    tvlUSD: 355407342.54044396,
    priceUSD: 35088.86120425968,
    priceUSDChange: -2.618350076192581,
    priceUSDChangeWeek: -7.376253544870644,
  },
  {
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    volumeUSD: 268786935.68089294,
    tvlUSD: 354123552.0459724,
    priceUSD: 0.9262374645512935,
    priceUSDChange: -7.872677670650831,
    priceUSDChangeWeek: -7.376253544870639,
  },
  {
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    name: 'Tether USD',
    symbol: 'USDT',
    volumeUSD: 959754832.7376099,
    tvlUSD: 345260971.436058,
    priceUSD: 0.9262374645512935,
    priceUSDChange: -7.872677670650831,
    priceUSDChangeWeek: -7.376253544870639,
  },
  {
    address: '0x853d955acef822db058eb8505911ed77f175b99e',
    name: 'Frax',
    symbol: 'FRAX',
    volumeUSD: 125139585.65821171,
    tvlUSD: 240495768.76421383,
    priceUSD: 0.9252378662924091,
    priceUSDChange: -7.970232255827052,
    priceUSDChangeWeek: -7.3762535448706466,
  },
  {
    address: '0xfe2e637202056d30016725477c5da089ab0a043a',
    name: 'StakeWise Staked ETH2',
    symbol: 'sETH2',
    volumeUSD: 1476135.104903251,
    tvlUSD: 108415436.96995524,
    priceUSD: 2554.8515523850383,
    priceUSDChange: -4.762185727646319,
    priceUSDChangeWeek: -7.376253544870644,
  },
  {
    address: '0xbbaec992fc2d637151daf40451f160bf85f3c8c1',
    name: 'USD Mapped Token',
    symbol: 'USDM',
    volumeUSD: 39712.253926000034,
    tvlUSD: 50157329.86415851,
    priceUSD: 0.8541766949048718,
    priceUSDChange: -7.121441788048511,
    priceUSDChangeWeek: -7.376253544870649,
  },
  {
    address: '0xefa6903aa49cd539c079ac4b0a090db432615822',
    name: 'SW Yield Fund',
    symbol: 'SWYF',
    volumeUSD: 0,
    tvlUSD: 50134926.41602759,
    priceUSD: 0.8426041206482799,
    priceUSDChange: -4.762799987753816,
    priceUSDChangeWeek: -7.376253544870639,
  },
  {
    address: '0x956f47f50a910163d8bf957cf5846d573e7f87ca',
    name: 'Fei USD',
    symbol: 'FEI',
    volumeUSD: 40252230.4865036,
    tvlUSD: 33577565.6065209,
    priceUSD: 0.9255639753540719,
    priceUSDChange: -7.657566979172092,
    priceUSDChangeWeek: -7.3762535448706315,
  },
  {
    address: '0x1a7e4e63778b4f12a199c062f3efdd288afcbce8',
    name: 'agEUR',
    symbol: 'agEUR',
    volumeUSD: 15248020.948954701,
    tvlUSD: 35726803.80075755,
    priceUSD: 0.9723248941138849,
    priceUSDChange: -8.233252784793274,
    priceUSDChangeWeek: -7.37625354487063,
  },
  {
    address: '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
    name: 'Paxos Standard',
    symbol: 'PAX',
    volumeUSD: 23663395.30176592,
    tvlUSD: 37259148.83150503,
    priceUSD: 0.9253649854694012,
    priceUSDChange: -7.587031896847778,
    priceUSDChangeWeek: -7.3762535448706394,
  },
  {
    address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    name: 'ApeCoin',
    symbol: 'APE',
    volumeUSD: 397438488.11135197,
    tvlUSD: 38769515.49587663,
    priceUSD: 15.4323107546582,
    priceUSDChange: 16.189143623600422,
    priceUSDChangeWeek: -7.376253544870652,
  },
  {
    address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    name: 'HEX',
    symbol: 'HEX',
    volumeUSD: 82727305.36573887,
    tvlUSD: 23873661.802879147,
    priceUSD: 0.1491053896862411,
    priceUSDChange: -17.518090060651463,
    priceUSDChangeWeek: -7.376253544870637,
  },
  {
    address: '0xc669928185dbce49d2230cc9b0979be6dc797957',
    name: 'BitTorrent',
    symbol: 'BTT',
    volumeUSD: 5866015.394887194,
    tvlUSD: 26083439.031708285,
    priceUSD: 0.0000015608996903302862,
    priceUSDChange: -6.099017942349453,
    priceUSDChangeWeek: -7.376253544870639,
  },
  {
    address: '0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5',
    name: 'BitDAO',
    symbol: 'BIT',
    volumeUSD: 177690.8870227039,
    tvlUSD: 0,
    priceUSD: 0,
    priceUSDChange: 0,
    priceUSDChangeWeek: 0,
  },
  {
    address: '0x6810e776880c02933d47db1b9fc05908e5386b96',
    name: 'Gnosis Token',
    symbol: 'GNO',
    volumeUSD: 4044431.1667901278,
    tvlUSD: 24289593.385139793,
    priceUSD: 286.8396874673127,
    priceUSDChange: -2.071707316620689,
    priceUSDChangeWeek: -7.376253544870629,
  },
  {
    address: '0x419d0d8bdd9af5e606ae2232ed285aff190e711b',
    name: 'FunFair',
    symbol: 'FUN',
    volumeUSD: 2780715.6963315457,
    tvlUSD: 19591147.24808743,
    priceUSD: 0.009521592577929111,
    priceUSDChange: -6.981747965867573,
    priceUSDChangeWeek: -7.376253544870648,
  },
  {
    address: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
    name: 'Binance USD',
    symbol: 'BUSD',
    volumeUSD: 7500116.7754246,
    tvlUSD: 22639932.86839381,
    priceUSD: 0.9148840542091875,
    priceUSDChange: -8.890254296808658,
    priceUSDChangeWeek: -7.376253544870641,
  },
  {
    address: '0xf1ca9cb74685755965c7458528a36934df52a3ef',
    name: 'AVINOC Token',
    symbol: 'AVINOC',
    volumeUSD: 2955615.245040044,
    tvlUSD: 21159795.632608507,
    priceUSD: 0.295116031197156,
    priceUSDChange: -8.444696430845386,
    priceUSDChangeWeek: -7.37625354487063,
  },
  {
    address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    name: 'Uniswap',
    symbol: 'UNI',
    volumeUSD: 7166541.203314781,
    tvlUSD: 18895038.804342415,
    priceUSD: 6.400419753030942,
    priceUSDChange: -14.130127629224013,
    priceUSDChangeWeek: -7.376253544870648,
  },
  {
    address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    name: 'SHIBA INU',
    symbol: 'SHIB',
    volumeUSD: 7889619.10478735,
    tvlUSD: 19045187.11541868,
    priceUSD: 0.000019701904563066845,
    priceUSDChange: -0.2172600297501252,
    priceUSDChangeWeek: -7.376253544870641,
  },
  {
    address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    name: 'LooksRare Token',
    symbol: 'LOOKS',
    volumeUSD: 217343702.17029476,
    tvlUSD: 16026693.430452334,
    priceUSD: 1.3415529986816552,
    priceUSDChange: -7.957367509705697,
    priceUSDChangeWeek: -7.376253544870632,
  },
  {
    address: '0xc00e94cb662c3520282e6f5717214004a7f26888',
    name: 'Compound',
    symbol: 'COMP',
    volumeUSD: 1486673.6395015717,
    tvlUSD: 15574300.863043044,
    priceUSD: 95.805541702751,
    priceUSDChange: -6.718266206231194,
    priceUSDChangeWeek: -7.3762535448706394,
  },
  {
    address: '0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a',
    name: 'Adshares',
    symbol: 'ADS',
    volumeUSD: 83389.16742588393,
    tvlUSD: 17122882.84058983,
    priceUSD: 3.9592951797335947,
    priceUSDChange: -4.321767364907876,
    priceUSDChangeWeek: -7.376253544870645,
  },
  {
    address: '0xb4efd85c19999d84251304bda99e90b92300bd93',
    name: 'Rocket Pool',
    symbol: 'RPL',
    volumeUSD: 973020.5788265467,
    tvlUSD: 16773707.650912149,
    priceUSD: 28.174669159723198,
    priceUSDChange: -3.9896191689600715,
    priceUSDChangeWeek: -7.37625354487063,
  },
  {
    address: '0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd',
    name: 'ETH 2x Flexible Leverage Index',
    symbol: 'ETH2x-FLI',
    volumeUSD: 13470639.725729465,
    tvlUSD: 16938072.783770993,
    priceUSD: 59.730389393281634,
    priceUSDChange: -0.7761399085057497,
    priceUSDChangeWeek: -7.376253544870645,
  },
  {
    address: '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919',
    name: 'Rai Reflex Index',
    symbol: 'RAI',
    volumeUSD: 15931970.299742103,
    tvlUSD: 4725084.463618565,
    priceUSD: 2.7898510635324887,
    priceUSDChange: -7.26120514985116,
    priceUSDChangeWeek: -7.376253544870642,
  },
  {
    address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    name: 'Maker',
    symbol: 'MKR',
    volumeUSD: 5891858.404828429,
    tvlUSD: 16023164.130673561,
    priceUSD: 1316.9020314221375,
    priceUSDChange: -1.9471332928593832,
    priceUSDChangeWeek: -7.376253544870641,
  },
  {
    address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    name: 'ChainLink Token',
    symbol: 'LINK',
    volumeUSD: 18848677.43798065,
    tvlUSD: 14506678.438621657,
    priceUSD: 10.236588020915033,
    priceUSDChange: -4.144863948441126,
    priceUSDChangeWeek: -7.376253544870652,
  },
  {
    address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    name: 'Matic Token',
    symbol: 'MATIC',
    volumeUSD: 9678714.278233528,
    tvlUSD: 14498679.79293569,
    priceUSD: 0.9941394810807169,
    priceUSDChange: -4.628417034850725,
    priceUSDChangeWeek: -7.376253544870637,
  },
  {
    address: '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9',
    name: 'FTT',
    symbol: 'FTX Token',
    volumeUSD: 1200601.5415579677,
    tvlUSD: 13240305.384007294,
    priceUSD: 35.41835345534067,
    priceUSDChange: -1.1240688746221201,
    priceUSDChangeWeek: -7.376253544870637,
  },
  {
    address: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
    name: 'STASIS EURS Token',
    symbol: 'EURS',
    volumeUSD: 22060399.20059383,
    tvlUSD: 10553529.302371057,
    priceUSD: 0.9543609991368089,
    priceUSDChange: -8.997030301592538,
    priceUSDChangeWeek: -7.376253544870634,
  },
  {
    address: '0x0000000000085d4780b73119b644ae5ecd22b376',
    name: 'TrueUSD',
    symbol: 'TUSD',
    volumeUSD: 4798063.329289645,
    tvlUSD: 9799604.676905446,
    priceUSD: 0.9260309907033115,
    priceUSDChange: -7.723521387964712,
    priceUSDChangeWeek: -7.376253544870642,
  },
  {
    address: '0x3e5d9d8a63cc8a88748f229999cf59487e90721e',
    name: 'MetalSwap',
    symbol: 'XMT',
    volumeUSD: 76279.473877877,
    tvlUSD: 8702444.995979035,
    priceUSD: 0.19736090320019273,
    priceUSDChange: -8.186264700370414,
    priceUSDChangeWeek: -7.376253544870644,
  },
  {
    address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
    name: 'Aave Token',
    symbol: 'AAVE',
    volumeUSD: 916635.9114561677,
    tvlUSD: 8806976.908396943,
    priceUSD: 133.1834866222135,
    priceUSDChange: -2.9307492987434816,
    priceUSDChangeWeek: -7.376253544870637,
  },
  {
    address: '0x35a532d376ffd9a705d0bb319532837337a398e7',
    name: 'Wrapped DogeCoin',
    symbol: 'WDOGE',
    volumeUSD: 0,
    tvlUSD: 8437924.655251516,
    priceUSD: 0.11089812268801384,
    priceUSDChange: -4.762799987753814,
    priceUSDChangeWeek: -7.3762535448706466,
  },
  {
    address: '0xf5581dfefd8fb0e4aec526be659cfab1f8c781da',
    name: 'HOPR Token',
    symbol: 'HOPR',
    volumeUSD: 145685.28773973882,
    tvlUSD: 7994449.4495685045,
    priceUSD: 0.15095100090695807,
    priceUSDChange: -6.041320000397512,
    priceUSDChangeWeek: -7.376253544870635,
  },
  {
    address: '0x111111111117dc0aa78b770fa6a738034120c302',
    name: '1INCH Token',
    symbol: '1INCH',
    volumeUSD: 33663687.87395382,
    tvlUSD: 9922289.292299232,
    priceUSD: 1.0618430600910498,
    priceUSDChange: -20.45531829198932,
    priceUSDChangeWeek: -7.3762535448706545,
  },
  {
    address: '0x77777feddddffc19ff86db637967013e6c6a116c',
    name: 'TornadoCash',
    symbol: 'TORN',
    volumeUSD: 3292980.572380282,
    tvlUSD: 8117767.378367279,
    priceUSD: 48.48270393174114,
    priceUSDChange: -14.141263531260972,
    priceUSDChangeWeek: -7.376253544870644,
  },
  {
    address: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
    name: 'renBTC',
    symbol: 'renBTC',
    volumeUSD: 128371571.17088151,
    tvlUSD: 11063321.143886302,
    priceUSD: 35149.181926035526,
    priceUSDChange: -2.4476493162676904,
    priceUSDChangeWeek: -7.376253544870644,
  },
  {
    address: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
    name: 'SAND',
    symbol: 'SAND',
    volumeUSD: 6477983.8542968035,
    tvlUSD: 9155279.469865495,
    priceUSD: 1.899294043670159,
    priceUSDChange: -7.686388746711034,
    priceUSDChangeWeek: -7.376253544870651,
  },
  {
    address: '0xf1b99e3e573a1a9c5e6b2ce818b617f0e664e86b',
    name: 'Opyn Squeeth',
    symbol: 'oSQTH',
    volumeUSD: 5688691.248792887,
    tvlUSD: 7424745.129078778,
    priceUSD: 525.1618495965809,
    priceUSDChange: -1.6969029685809223,
    priceUSDChangeWeek: -7.376253544870634,
  },
  {
    address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    name: 'Wrapped UST Token',
    symbol: 'UST',
    volumeUSD: 135010345.87972164,
    tvlUSD: 8381381.947582184,
    priceUSD: 0.9265908282250563,
    priceUSDChange: -7.725429810421229,
    priceUSDChangeWeek: -7.376253544870642,
  },
  {
    address: '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72',
    name: 'Ethereum Name Service',
    symbol: 'ENS',
    volumeUSD: 48589213.178604126,
    tvlUSD: 5397136.247084866,
    priceUSD: 20.165652202253288,
    priceUSDChange: 14.111466439796159,
    priceUSDChangeWeek: -7.376253544870644,
  },
  {
    address: '0x6f40d4a6237c257fff2db00fa0510deeecd303eb',
    name: 'Instadapp',
    symbol: 'INST',
    volumeUSD: 44970.96158415079,
    tvlUSD: 6469106.446816378,
    priceUSD: 1.2504330109638602,
    priceUSDChange: 3.7887767294328647,
    priceUSDChangeWeek: -7.3762535448706394,
  },
  {
    address: '0xff20817765cb7f73d4bde2e66e067e58d11095c2',
    name: 'Amp',
    symbol: 'AMP',
    volumeUSD: 744600.113578394,
    tvlUSD: 5810177.826187007,
    priceUSD: 0.01763770898432596,
    priceUSDChange: -3.155635988616955,
    priceUSDChangeWeek: -7.376253544870644,
  },
  {
    address: '0x3472a5a71965499acd81997a54bba8d852c6e53d',
    name: 'Badger',
    symbol: 'BADGER',
    volumeUSD: 2200473.705986172,
    tvlUSD: 5916504.192310071,
    priceUSD: 7.013079709588997,
    priceUSDChange: -3.632774032333953,
    priceUSDChangeWeek: -7.376253544870639,
  },
  {
    address: '0x03352d267951e96c6f7235037c5dfd2ab1466232',
    name: 'oneFOX',
    symbol: 'oneFOX',
    volumeUSD: 830557.7162650004,
    tvlUSD: 8703031.683284624,
    priceUSD: 0.9166308908126889,
    priceUSDChange: -9.17109458102791,
    priceUSDChangeWeek: -7.376253544870637,
  },
  {
    address: '0xd533a949740bb3306d119cc777fa900ba034cd52',
    name: 'Curve DAO Token',
    symbol: 'CRV',
    volumeUSD: 9581675.34762752,
    tvlUSD: 5295101.536812066,
    priceUSD: 1.8697336590860294,
    priceUSDChange: -19.201259409316425,
    priceUSDChangeWeek: -7.376253544870634,
  },
];
