export * from './transactionData.queries';

export const TRANSACTIONS_DATA = [
  {
    hash: '0x4b4fcc5933199a304cd0daee3a5ceeb2626ecc0795b84f6b5ce044d865d416c9',
    type: 0,
    timestamp: '1652077915',
    sender: '0x93a692fe5477902c3cf5f6997c1cf59a3712ced4',
    token0Symbol: 'SYN',
    token1Symbol: 'ETH',
    token0Address: '0x0f2d719407fdbeff09d87557abb7232601fd9f29',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1021.0191024369378,
    amountToken0: -319.5048784899681,
    amountToken1: 0.41878021180134484,
  },
  {
    hash: '0x4b4fcc5933199a304cd0daee3a5ceeb2626ecc0795b84f6b5ce044d865d416c9',
    type: 0,
    timestamp: '1652077915',
    sender: '0x93a692fe5477902c3cf5f6997c1cf59a3712ced4',
    token0Symbol: 'XMON',
    token1Symbol: 'ETH',
    token0Address: '0x3aada3e213abf8529606924d8d1c55cbdc70bf74',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1021.0191024369378,
    amountToken0: 0.036360878277503124,
    amountToken1: -0.41878021180134484,
  },
  {
    hash: '0x357bc7155270b0c7b5b549dac98dd3ad85ce09e14c318673141050096de8f5f0',
    type: 0,
    timestamp: '1652077915',
    sender: '0xefd4c7dd015cff1da4f006610c57e25a4adbe062',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 604.7654498899037,
    amountToken0: -605.344529,
    amountToken1: 0.2478125,
  },
  {
    hash: '0x9f2816b790079ec667fc30d7f9f17383877b4a5a2cfcc53eda0e2640efc372b0',
    type: 0,
    timestamp: '1652077888',
    sender: '0xc1a80d351232fd07ee5733b5f581e01c269068a9',
    token0Symbol: 'MOONEY',
    token1Symbol: 'ETH',
    token0Address: '0x20d4db1946859e2adb0e5acc2eac58047ad41395',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 22638.80985122836,
    amountToken0: 986482.8371272045,
    amountToken1: -9.28551244712226,
  },
  {
    hash: '0x9c9edad409098fe48b291c08e9e10d686fc5cb6009cbf005a2bcbf4a40a7401a',
    type: 0,
    timestamp: '1652077888',
    sender: '0x9aab3f81604c683a1a0d14019fbfe15bef7aa1ee',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 152881.38434431155,
    amountToken0: 153130.42739,
    amountToken1: -62.63528019443248,
  },
  {
    hash: '0x99f89fc881d36fc193d3d048a73caad63edbb7fa2bc7c30ed033c7fa91e04644',
    type: 0,
    timestamp: '1652077888',
    sender: '0x4603180bbb8221157880afaa84638e0fc467738d',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 72367.46999143518,
    amountToken0: 72470.325741,
    amountToken1: -29.639984585716515,
  },
  {
    hash: '0x904f34e68b74e7638d3f8fd765ae9e14a0b24c9eda81656867657c21ca6c435e',
    type: 0,
    timestamp: '1652077888',
    sender: '0x9d323dd2b3c5cef5518aa2c4c5773a1131f6b661',
    token0Symbol: 'ETH',
    token1Symbol: 'LOOKS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    amountUSD: 48030.14982844775,
    amountToken0: 19.7,
    amountToken1: -40130.31161663403,
  },
  {
    hash: '0x623ba156b8fd02e7c41775f56ed2279ddc3ba38148db983414ae622e18fd9fae',
    type: 0,
    timestamp: '1652077888',
    sender: '0x51d799582336b9c1a0c8b3c5588c504c20467e3d',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 9935.00411003592,
    amountToken0: 4.07,
    amountToken1: -9941.986474,
  },
  {
    hash: '0x4551c67a575c00f22fd3c366765fcd1db61dad80a930d903a15304ead4a79af1',
    type: 0,
    timestamp: '1652077888',
    sender: '0xe3aa39edd9ed6c6bf2dca78e9a6f0462aee8c43e',
    token0Symbol: 'ETH',
    token1Symbol: 'LOOKS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    amountUSD: 1662.6608511482373,
    amountToken0: 0.6819553735437273,
    amountToken1: -1387.4327791886005,
  },
  {
    hash: '0xb3194dc3006cfd8476be6c1fbfb21aba0681e23fd863e868f6e8782faf9b489e',
    type: 0,
    timestamp: '1652077880',
    sender: '0x2aa4e7aae2750c450bd3016f4c3d97d2cb59d123',
    token0Symbol: 'ETH',
    token1Symbol: 'SOL',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xd31a59c85ae9d8edefec411d448f90841571b89c',
    amountUSD: 312.6131767238596,
    amountToken0: -0.12828614007998068,
    amountToken1: 4.224127781,
  },
  {
    hash: '0x753e78f11948a0864af531931d93337495e6675eef18ee3e7f399528db74c0ac',
    type: 0,
    timestamp: '1652077880',
    sender: '0xd282cecd9f9541b80beaf4c60bc1a52528096351',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 34946.4890122557,
    amountToken0: 35000,
    amountToken1: -14.320587223702926,
  },
  {
    hash: '0x4c299a15027b97624d36dc8b908c5494ca19b61995b9cd929e2e74afb1275cc6',
    type: 0,
    timestamp: '1652077880',
    sender: '0xe1997c479a35ca8f6e3a5343ff866490b63debcf',
    token0Symbol: 'agEUR',
    token1Symbol: 'ETH',
    token0Address: '0x1a7e4e63778b4f12a199c062f3efdd288afcbce8',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 15347.349737756584,
    amountToken0: 14666.74618297215,
    amountToken1: -6.298046291418615,
  },
  {
    hash: '0x4c299a15027b97624d36dc8b908c5494ca19b61995b9cd929e2e74afb1275cc6',
    type: 0,
    timestamp: '1652077880',
    sender: '0xe1997c479a35ca8f6e3a5343ff866490b63debcf',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 15341.98756320183,
    amountToken0: -15359.561421,
    amountToken1: 6.2886341,
  },
  {
    hash: '0x4c299a15027b97624d36dc8b908c5494ca19b61995b9cd929e2e74afb1275cc6',
    type: 0,
    timestamp: '1652077880',
    sender: '0xe1997c479a35ca8f6e3a5343ff866490b63debcf',
    token0Symbol: 'agEUR',
    token1Symbol: 'USDC',
    token0Address: '0x1a7e4e63778b4f12a199c062f3efdd288afcbce8',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 15359.56142,
    amountToken0: -14666.746182017248,
    amountToken1: 15359.56142,
  },
  {
    hash: '0x4786a87e09443bbab265b802f1834c3e2f433dd54453e5fc934e578e61680fa7',
    type: 0,
    timestamp: '1652077880',
    sender: '0x89f547ed40b8e921c566505ccb71c69f398adbff',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 2496.632331354713,
    amountToken0: -1.023153608330916,
    amountToken1: 2500,
  },
  {
    hash: '0x437e4c0be94b7fc4e05fe7eff00211fd3fbc88476d1c9eae1c0f1bfb8b9065a4',
    type: 0,
    timestamp: '1652077880',
    sender: '0x6046945c5b5ef5933b8e73a98a6ad7bf3e031df7',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 45141.21703557625,
    amountToken0: -18.49562920050671,
    amountToken1: 45211.490349,
  },
  {
    hash: '0x11cd401963474afbadb120a949bd14094401ec08ffb92f6bfa889161a6ec767c',
    type: 0,
    timestamp: '1652077880',
    sender: '0xecea9132bf88f52dd4d9a33cebebb4361ba4991b',
    token0Symbol: 'SHI',
    token1Symbol: 'ETH',
    token0Address: '0x243cacb4d5ff6814ad668c3e225246efa886ad5a',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 292.42115464285627,
    amountToken0: -505080469.68583345,
    amountToken1: 0.12,
  },
  {
    hash: '0x0ae695b45e392f2094a19b90c0949cd294522f6c7730b7a7da1cb01cd01859ea',
    type: 0,
    timestamp: '1652077880',
    sender: '0xeea1c3cbae920fc2f0afc1a46272d69ad7395519',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 49926.37272330739,
    amountToken0: -20.464,
    amountToken1: 49979.398636,
  },
  {
    hash: '0x0097a53d6a88137feae65992f534c4dd309235f418619a2a3460ae77891c6348',
    type: 0,
    timestamp: '1652077880',
    sender: '0x8176d7b793a73626880bc43dfd9a9f97d338ca88',
    token0Symbol: 'TCS',
    token1Symbol: 'USDC',
    token0Address: '0x511d35c52a3c244e7b8bd92c0c297755fbd89212',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 293.61223,
    amountToken0: 2257.62,
    amountToken1: -293.61223,
  },
  {
    hash: '0x0097a53d6a88137feae65992f534c4dd309235f418619a2a3460ae77891c6348',
    type: 0,
    timestamp: '1652077880',
    sender: '0x8176d7b793a73626880bc43dfd9a9f97d338ca88',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 293.1320870159498,
    amountToken0: 293.61223,
    amountToken1: -0.12009470835555325,
  },
  {
    hash: '0xe778a03c816c080fedf6f2c5d6c2f918a0d70e7d0456a715d0d6976491ead6c8',
    type: 0,
    timestamp: '1652077859',
    sender: '0x60b86af869f23aeb552fb7f3cabd11b829f6ab2f',
    token0Symbol: 'WBTC',
    token1Symbol: 'USDT',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 26987.749199202277,
    amountToken0: -0.80755844,
    amountToken1: 27006.272137,
  },
  {
    hash: '0xdd7c11f1a19bc66d2535c86bafaa705294617f1603d0b22cfb7fed548a4a8515',
    type: 0,
    timestamp: '1652077859',
    sender: '0x431b5a84acc1297eda88259f300262f1bc3a74f3',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 138616.76993203556,
    amountToken0: -56.79181819607874,
    amountToken1: 138856.83552,
  },
  {
    hash: '0xdb46ff818884cf5b7b7d0fba8929b97679bcc1975a908739e2253fec5d7b8e98',
    type: 0,
    timestamp: '1652077859',
    sender: '0x1c8f6a5f009e051cab9c3851ca2da2c936b2775a',
    token0Symbol: 'WBTC',
    token1Symbol: 'USDC',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 52187.683250163536,
    amountToken0: -1.56249843,
    amountToken1: 52243.174334,
  },
  {
    hash: '0xc6678e793005d899b7b52206723f3636d08ba84364933a2bf199da7bbace4226',
    type: 0,
    timestamp: '1652077859',
    sender: '0xbbad96336943a36e3e292d973b3382dd0ba4d1ef',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 106089.74005417766,
    amountToken0: 106261.8381767103,
    amountToken1: -43.48108761452295,
  },
  {
    hash: '0xc51ae6c21f3e86e81f9b49dad83ae79ad4264365f471eb79f72b900205ec8e8b',
    type: 0,
    timestamp: '1652077859',
    sender: '0x0cac3d1a887206e0f6169222c4504301a8b4b993',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 141523.59904447477,
    amountToken0: 141752.812609,
    amountToken1: -58.01650612436043,
  },
  {
    hash: '0xbf7c474dd180784c0b261e4168cbd774cb7b5b63a4cbe6c066b4f4a942d32a1e',
    type: 0,
    timestamp: '1652077859',
    sender: '0x76e40d0a69fd81826b5eb7d18145626d46eafdef',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 299513.89716506493,
    amountToken0: 300000.530426,
    amountToken1: -122.78277745230396,
  },
  {
    hash: '0xb910ecc1a1e7a13650d9c5952c85dffa6585fdf1d95335c6fdf8e0ad771353bb',
    type: 0,
    timestamp: '1652077859',
    sender: '0x6bd88652b93753cd52b6858032355d040d33203f',
    token0Symbol: 'ETH',
    token1Symbol: 'LOOKS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    amountUSD: 110069.16375346272,
    amountToken0: -45.19520213994763,
    amountToken1: 92473.66039100457,
  },
  {
    hash: '0xb910ecc1a1e7a13650d9c5952c85dffa6585fdf1d95335c6fdf8e0ad771353bb',
    type: 0,
    timestamp: '1652077859',
    sender: '0x6bd88652b93753cd52b6858032355d040d33203f',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 110198.74012973136,
    amountToken0: 45.19520213994763,
    amountToken1: -110328.316506,
  },
  {
    hash: '0xa856ad15d97dff418a5dbd388063214c3d0be2f9930429644bbd3fcf34bac58b',
    type: 0,
    timestamp: '1652077859',
    sender: '0x26bce6ecb5b10138e4bf14ac0ffcc8727fef3b2e',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 53582.63572756312,
    amountToken0: -21.9580802874647,
    amountToken1: 53688.189366,
  },
  {
    hash: '0x9fc0171ff511428adc65a5ad9b37f4a2e5c1e2f60e8b5ea1f24a9a593028df19',
    type: 0,
    timestamp: '1652077859',
    sender: '0x9f4e83963f365efd197e339f6e6ceda790b1922d',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 5366.935150990142,
    amountToken0: 2.2,
    amountToken1: -5375.952895,
  },
  {
    hash: '0x8bc492dd4eb8c1356cf6138eeeac589158a34031c0d551dbc3e0787c425691fe',
    type: 0,
    timestamp: '1652077859',
    sender: '0xbeb67143f79d0640b94feead7cad7e8935cae33f',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1003.2145375882468,
    amountToken0: -1004.665611,
    amountToken1: 0.4113313912448662,
  },
  {
    hash: '0x83ca0efd4424e64bbe654ec61d2b043e6481336f9b4e95a5e9daea28cb60fde0',
    type: 0,
    timestamp: '1652077859',
    sender: '0xb1520b468ec6a426b65509fd8b65feb10ec4d926',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 47171.519492640255,
    amountToken0: -19.339332411623793,
    amountToken1: 47243.7,
  },
  {
    hash: '0x83bf871086c5321701ccea78d3e2d420bd353cde403d29a45c3c23be6b742a70',
    type: 0,
    timestamp: '1652077859',
    sender: '0x78154f73612c39b73c90d311417ce3104f8f5d38',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 170191.5747289596,
    amountToken0: 170508.23725,
    amountToken1: -69.75187903615951,
  },
  {
    hash: '0x83bf871086c5321701ccea78d3e2d420bd353cde403d29a45c3c23be6b742a70',
    type: 0,
    timestamp: '1652077859',
    sender: '0x78154f73612c39b73c90d311417ce3104f8f5d38',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 50985.56421170015,
    amountToken0: 1.52881365,
    amountToken1: -20.925704563670173,
  },
  {
    hash: '0x83bf871086c5321701ccea78d3e2d420bd353cde403d29a45c3c23be6b742a70',
    type: 0,
    timestamp: '1652077859',
    sender: '0x78154f73612c39b73c90d311417ce3104f8f5d38',
    token0Symbol: 'WBTC',
    token1Symbol: 'USDC',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 51080.39144983306,
    amountToken0: -1.52881365,
    amountToken1: 51152.471174,
  },
  {
    hash: '0x44b58ae416cbebe1ad14fd704a1492f1f7c7601be75bda5fdee7c822c65e3cea',
    type: 0,
    timestamp: '1652077859',
    sender: '0xff82bf5238637b7e5e345888bab9cd99f5ebe331',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 35662.09288746928,
    amountToken0: 35707.98188239531,
    amountToken1: -14.6105283391692,
  },
  {
    hash: '0x360d53a7c92be0d6e45107cf4b4f8bb2d09a5c97ff84be252fe53c54c8ac90fe',
    type: 0,
    timestamp: '1652077859',
    sender: '0x24f5db068ac60d3a096a54255beb3ab63abad748',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 99.80567778535485,
    amountToken0: 100,
    amountToken1: -0.040901149758310884,
  },
  {
    hash: '0x2d255e4a2c39c683f7a915203a15554704ac3c7490650e669486360c449eb014',
    type: 0,
    timestamp: '1652077859',
    sender: '0xc3a167552642bc253beb0b4d5242f9c9a08fdd66',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 4281.821464589357,
    amountToken0: -4287.284984,
    amountToken1: 1.7559037896210312,
  },
  {
    hash: '0x2d255e4a2c39c683f7a915203a15554704ac3c7490650e669486360c449eb014',
    type: 0,
    timestamp: '1652077859',
    sender: '0xc3a167552642bc253beb0b4d5242f9c9a08fdd66',
    token0Symbol: 'ETH',
    token1Symbol: 'COTI',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xddb3422497e61e13543bea06989c0789117555c5',
    amountUSD: 1489.4991414836563,
    amountToken0: -0.6115992208082394,
    amountToken1: 8750,
  },
  {
    type: 2,
    hash: '0x0e149198703c26eaf92cab0da486d900acbff2ca7bb0c0b571652f5383cb9714',
    timestamp: '1652077859',
    sender: '0x8ed4d3c67ee1a73f5df8ca3e10698f1cd4c5b14c',
    token0Symbol: 'POW',
    token1Symbol: 'ETH',
    token0Address: '0x43ab765ee05075d78ad8aa79dcb1978ca3079258',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 0,
    amountToken0: 0,
    amountToken1: 0,
  },
  {
    hash: '0x50e5dfee6d2b105dcd684309d648f12204a495cd346dae56dbeee9afaa48fdb0',
    type: 0,
    timestamp: '1652077843',
    sender: '0xa855d1198c67839e596b9a5d7c46f8ea31cfefde',
    token0Symbol: 'LYXe',
    token1Symbol: 'ETH',
    token0Address: '0xa8b919680258d369114910511cc87595aec0be6d',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2448.910205553618,
    amountToken0: -304.0461354260708,
    amountToken1: 1.0055404073976584,
  },
  {
    hash: '0xf9b8877c7e7382f1a6b457375f3630d4e441d0d299ddf7c4ff0236f1200a3d07',
    type: 0,
    timestamp: '1652077841',
    sender: '0x2f995819a80e6f48dcde3e44d71b45669d33db5a',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5662.011581810861,
    amountToken0: -5667.526189,
    amountToken1: 2.3225989500986683,
  },
  {
    hash: '0xf9b8877c7e7382f1a6b457375f3630d4e441d0d299ddf7c4ff0236f1200a3d07',
    type: 0,
    timestamp: '1652077841',
    sender: '0x2f995819a80e6f48dcde3e44d71b45669d33db5a',
    token0Symbol: 'LYXe',
    token1Symbol: 'ETH',
    token0Address: '0xa8b919680258d369114910511cc87595aec0be6d',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5656.496974621721,
    amountToken0: 700,
    amountToken1: -2.3225989500986683,
  },
  {
    hash: '0xd1d25ed817015a552b3cb3a653a489472322301dd4e4aa0dccd71e58b869ef2a',
    type: 0,
    timestamp: '1652077841',
    sender: '0xe5254e61b579d02ec9a573593a9833a238f2a97e',
    token0Symbol: 'ETH',
    token1Symbol: 'TRU',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf65b5c5104c4fafd4b709d9d60a185eae063276c',
    amountUSD: 6165.545791297153,
    amountToken0: -2.53161811027216,
    amountToken1: 40502.4,
  },
  {
    hash: '0xd1d25ed817015a552b3cb3a653a489472322301dd4e4aa0dccd71e58b869ef2a',
    type: 0,
    timestamp: '1652077841',
    sender: '0xe5254e61b579d02ec9a573593a9833a238f2a97e',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 6170.797727148577,
    amountToken0: 2.53161811027216,
    amountToken1: -6176.049663,
  },
  {
    type: 1,
    hash: '0x977e2f64c1e02298b7ef69d7943e4022507c5cd38d861e632be98f6f933aaa38',
    timestamp: '1652077841',
    sender: '0x5ac39e51d7a3bf515650b6207b34e02e328953d0',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 0.35220500042331015,
    amountToken0: 0.177136,
    amountToken1: 0.000071884609574143,
  },
  {
    hash: '0x87668bbd60fcc5777825f01976077e689015708b6a7c605372080cd77aa813ec',
    type: 0,
    timestamp: '1652077841',
    sender: '0xc5c451d023513d9cbda816ca93ffab058ca26950',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 12510.332476312917,
    amountToken0: 5.1320430695616395,
    amountToken1: -12522,
  },
  {
    type: 2,
    hash: '0x7975669294857ab86cec46f6e7c07b971499e9c8e99cf516d43826a61c4f1d8f',
    timestamp: '1652077841',
    sender: '0xb800adfc068340040243ddc374001985c16c5481',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 698863.7616749622,
    amountToken0: 0,
    amountToken1: 286.95856223574197,
  },
  {
    type: 2,
    hash: '0x6b2f5a59889ee3b6374d092f5710147bc996cd0cc7b2b7105b0be45c39cf8ba5',
    timestamp: '1652077841',
    sender: '0x133523d61f91253059407cf062750777384ba80e',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 3625.999533422595,
    amountToken0: 1557.426045,
    amountToken1: 0.8493713748929491,
  },
  {
    hash: '0x405296f219baacffae72d8a42fe6b387ab9422ae04586930900d16153f97799c',
    type: 0,
    timestamp: '1652077841',
    sender: '0xab92bf44a1f8840ca19b364a49332c7557608599',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 2437.8496695864283,
    amountToken0: 1,
    amountToken1: -2440.282336,
  },
  {
    hash: '0x0f9095cd2cc03176ad0faafce092ea8c54a0bb83d281dc32b8bbc3445cfded39',
    type: 0,
    timestamp: '1652077841',
    sender: '0xc8cc5644b026dee79ed42b34ce0eef18f135269d',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 97.41019950936078,
    amountToken0: -0.00291937,
    amountToken1: 0.04,
  },
  {
    hash: '0xff149f05fdbc30177bf92240d98762a1a9b6d0df3986daf3f31eda352add1eb9',
    type: 0,
    timestamp: '1652077805',
    sender: '0x26fd09c8b44af53df38a9bad41d5abc55a1786af',
    token0Symbol: 'ALEPH',
    token1Symbol: 'ETH',
    token0Address: '0x27702a26126e0b3702af63ee09ac4d1a084ef628',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2369.3778612425203,
    amountToken0: -9244.858646344337,
    amountToken1: 0.9728838462389396,
  },
  {
    hash: '0xec4e5bdd36e7627f587b1b56d90b87240b054857f5f3fbfc1bb12341e082ff73',
    type: 0,
    timestamp: '1652077805',
    sender: '0x185af8cf06431dacbc877ac754d21e86b4f68136',
    token0Symbol: 'ETH',
    token1Symbol: 'BOSON',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xc477d038d5420c6a9e0b031712f61c5120090de9',
    amountUSD: 58.02935991368959,
    amountToken0: -0.023827278793770865,
    amountToken1: 100,
  },
  {
    hash: '0xd223c55e69625bf50efa624a295dff64f6341ffd16aecbff78c614f7919b2e36',
    type: 0,
    timestamp: '1652077805',
    sender: '0xda5551f703ae83e0fc8f4f20ab868c2ec1c9c41c',
    token0Symbol: 'ETH',
    token1Symbol: 'LOOKS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    amountUSD: 462.7292306028427,
    amountToken0: 0.19,
    amountToken1: -385.3506622391111,
  },
  {
    hash: '0xce8d11b4ac375e9572671347befaecf6c5308ec0312d7253ce5facb52b07bbad',
    type: 0,
    timestamp: '1652077805',
    sender: '0xff82bf5238637b7e5e345888bab9cd99f5ebe331',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 65413.70207693391,
    amountToken0: 65515.518797,
    amountToken1: -26.8233570706829,
  },
  {
    hash: '0xa2690163f5660f29a70f63f434fda83b89bf791d1702d0fb2b1549e81ed6b64b',
    type: 0,
    timestamp: '1652077805',
    sender: '0x93f635372008b7c5d770aaa6ff313454c8dc498c',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 70712.69203800552,
    amountToken0: 70822.242866,
    amountToken1: -28.996456872130864,
  },
  {
    hash: '0x7ee43e610b81572bf931d0ca196963941952fc9f44d27db9aef99a1424f8d9e7',
    type: 0,
    timestamp: '1652077805',
    sender: '0x19d5750d7c5f8e359b53cb1e4892e536bd54fc82',
    token0Symbol: 'SNX',
    token1Symbol: 'ETH',
    token0Address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1943.9833298673993,
    amountToken0: 484.4221105527638,
    amountToken1: -0.7952119104832662,
  },
  {
    hash: '0x13ff98dee96bb246e8ded463d226e5b0337501fa355f013faa57dd45b5e93431',
    type: 0,
    timestamp: '1652077805',
    sender: '0x215bdf609cde8c51b5dd6f06642c0c5b98fbc954',
    token0Symbol: 'MOONEY',
    token1Symbol: 'ETH',
    token0Address: '0x20d4db1946859e2adb0e5acc2eac58047ad41395',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 4595.426788734102,
    amountToken0: 190000,
    amountToken1: -1.8869157859812875,
  },
  {
    hash: '0x13ff98dee96bb246e8ded463d226e5b0337501fa355f013faa57dd45b5e93431',
    type: 0,
    timestamp: '1652077805',
    sender: '0x215bdf609cde8c51b5dd6f06642c0c5b98fbc954',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 4600.658171383847,
    amountToken0: 1.8869157859812875,
    amountToken1: -4604.890162,
  },
  {
    hash: '0xf6a98bd626617e08b79b97e6cd4c43e7381858b88c8ede79791caed4c352091b',
    type: 0,
    timestamp: '1652077791',
    sender: '0xd7d7d68e4bdcf85c073485ab7bfd151b0c019f1f',
    token0Symbol: 'ALEPH',
    token1Symbol: 'ETH',
    token0Address: '0x27702a26126e0b3702af63ee09ac4d1a084ef628',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2215.2562256487245,
    amountToken0: 10000,
    amountToken1: -0.9097977867114306,
  },
  {
    hash: '0xdafbd2a0171154f6f708fe0ac8f8f58822d7e8a17722dda7ba86fdf367d8eb22',
    type: 0,
    timestamp: '1652077791',
    sender: '0x96b5ea9acf9c45fc9898291911fee702f5ca261e',
    token0Symbol: 'WMATIC',
    token1Symbol: 'ETH',
    token0Address: '0x7c9f4c87d911613fe9ca58b579f737911aad2d43',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 10036.663830554107,
    amountToken0: 10703.74732334047,
    amountToken1: -4.122021838052047,
  },
  {
    hash: '0xd26f4449715fbd54873afdd6c97c2541a801c210ff2caef2b951aa7d1011c6f0',
    type: 0,
    timestamp: '1652077791',
    sender: '0x0cac3d1a887206e0f6169222c4504301a8b4b993',
    token0Symbol: 'SAND',
    token1Symbol: 'ETH',
    token0Address: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 6363.984519793216,
    amountToken0: 3335.126121768077,
    amountToken1: -2.6136656174290294,
  },
  {
    hash: '0xc5cd63bde08e54ce1e747741b54cb9c704b1c00a9bfc5add5aa3559a795361b5',
    type: 0,
    timestamp: '1652077791',
    sender: '0xb58555fcba6479fced7de1485eb054943a09af7b',
    token0Symbol: 'ALEPH',
    token1Symbol: 'ETH',
    token0Address: '0x27702a26126e0b3702af63ee09ac4d1a084ef628',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 9430.737508750077,
    amountToken0: -40044.59008871749,
    amountToken1: 3.873170070881832,
  },
  {
    hash: '0x4cc34b8cb3271e9e0e38f7ec334c3970cf5306f74d26517204f9b4132b9a3437',
    type: 0,
    timestamp: '1652077791',
    sender: '0xb58555fcba6479fced7de1485eb054943a09af7b',
    token0Symbol: 'ALEPH',
    token1Symbol: 'ETH',
    token0Address: '0x27702a26126e0b3702af63ee09ac4d1a084ef628',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 9657.075208960077,
    amountToken0: 39855.581152495426,
    amountToken1: -3.966126152582996,
  },
  {
    type: 1,
    hash: '0x2fc72fa06a3b170a0fbbe55b15c97cbeb994bf9f974262bdf10a4dc8b72d30d2',
    timestamp: '1652077791',
    sender: '0xef7d14e1b36bdb49249f9ff99204690aff8bc8c5',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 17754.587480985014,
    amountToken0: 0.23,
    amountToken1: 4.140799838516978,
  },
  {
    hash: '0x26ad50e9026384c3a89474e6e652ad9f99ad0c415b4c3b965678677eb7969f82',
    type: 0,
    timestamp: '1652077791',
    sender: '0x798f9a3a3e410f8ea40fdea186022d851eb27509',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1706.278533953823,
    amountToken0: -1708.1350736981226,
    amountToken1: 0.7,
  },
  {
    hash: '0x2478eb28f1a48cb3f0c5c7164cea6cbc04845f838138a0818c76171ba5b3b776',
    type: 0,
    timestamp: '1652077791',
    sender: '0xaf981038151e090dfd71f791b0c2d89eb1f95669',
    token0Symbol: 'ETH',
    token1Symbol: 'CMB',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xc6db556fd9ec09bab6dfea320e52d8476f61d424',
    amountUSD: 106.23169009366026,
    amountToken0: -0.043628974114506114,
    amountToken1: 240920.36922264486,
  },
  {
    hash: '0xd74db7ffb9ece4fe476c97b01a260ac548049dcb7ca3112bd479cfdcb410d66d',
    type: 0,
    timestamp: '1652077783',
    sender: '0x40000019df2f05ae6c1eacdfa4886b5dc6040004',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 20846.447785109987,
    amountToken0: -0.62490431,
    amountToken1: 8.562081536273562,
  },
  {
    hash: '0xbd7186ffb94818d68ca22a5e5fbf27c95005f2fc5751710bc9285ea6bd3c3db4',
    type: 0,
    timestamp: '1652077783',
    sender: '0xf6b75cce953193b6469a4ff6e8ae3212adff7e74',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1108.7905590998625,
    amountToken0: -1109.914236,
    amountToken1: 0.45491481579912796,
  },
  {
    hash: '0xa7afa6263b2538358826abb33b584dccd57fef0f9957fcd996a44eea573c4c8a',
    type: 0,
    timestamp: '1652077783',
    sender: '0xdc4102b6784a8c1ba35946575991271ff8c843cd',
    token0Symbol: 'MET',
    token1Symbol: 'ETH',
    token0Address: '0x1ffe8a8177d3c261600a8bd8080d424d64b7fbc2',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 973.9554252625848,
    amountToken0: -32244.38855441064,
    amountToken1: 0.4,
  },
  {
    hash: '0x354e60dd0e7f048e15f446c8d33f12733cc31af217c7b0476b4a16511391b45d',
    type: 0,
    timestamp: '1652077783',
    sender: '0xb0fe3d791038f52f369f4ce9eac43a883900f89d',
    token0Symbol: 'USDC',
    token1Symbol: 'UST',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xa693b19d2931d498c5b318df961919bb4aee87a5',
    amountUSD: 63671.173283,
    amountToken0: 63671.173283,
    amountToken1: -64050.886381,
  },
  {
    hash: '0x85a7a9541d8848e507aa750613549f1bef6dbde2fb29b9e5e2dc89ed39c8b14d',
    type: 0,
    timestamp: '1652077777',
    sender: '0x76e40d0a69fd81826b5eb7d18145626d46eafdef',
    token0Symbol: 'UNI',
    token1Symbol: 'ETH',
    token0Address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5784.729023170768,
    amountToken0: 838.9636926705831,
    amountToken1: -2.3757674625042178,
  },
  {
    hash: '0xe38ccfd8200c24974178306b412cf9529c6f892f2fd1dd9185d4f6454bcac70e',
    type: 0,
    timestamp: '1652077753',
    sender: '0x1839f0640dc4764a7041043684a1d6f1606c1bb5',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 6825.590423417923,
    amountToken0: -6833.492869997754,
    amountToken1: 2.8,
  },
  {
    hash: '0xac250cbd2552649dc6ae17fc35863604d586196d784e24b82042591d9d61331a',
    type: 0,
    timestamp: '1652077753',
    sender: '0xc5584d60f62ae97fec2afba66d47a36ec4ff747c',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 483.2125733228843,
    amountToken0: -483.708489,
    amountToken1: 0.19825,
  },
  {
    hash: '0xa37ffa773a910be7dab1c1d5444a482971e05725bdec4214eab053580ef297c1',
    type: 0,
    timestamp: '1652077753',
    sender: '0xed6d254544bcc6dad9c2ec6ad2e50b9c4870270b',
    token0Symbol: 'SHIB',
    token1Symbol: 'ETH',
    token0Address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 193.08666305830744,
    amountToken0: -11094621.030652152,
    amountToken1: 0.0793,
  },
  {
    hash: '0x95624bec36785602bbefdc135796f8c73b6230a23c0f4a8e38419f9a7f9e3cd5',
    type: 0,
    timestamp: '1652077753',
    sender: '0x5de81af11cc158ba45928278f61fa94f0e78047a',
    token0Symbol: 'rETH',
    token1Symbol: 'ETH',
    token0Address: '0xae78736cd615f374d3085123a210448e74fc6393',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2495.477011336462,
    amountToken0: -1,
    amountToken1: 1.0248834583630622,
  },
  {
    hash: '0x6d32be5318c4f21bea9c1b688184026af4cba2c80216064ad599926b1450bd1c',
    type: 0,
    timestamp: '1652077753',
    sender: '0x5de81af11cc158ba45928278f61fa94f0e78047a',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1335.2061593969456,
    amountToken0: -1336.560965,
    amountToken1: 0.5478079670573328,
  },
  {
    hash: '0x6d32be5318c4f21bea9c1b688184026af4cba2c80216064ad599926b1450bd1c',
    type: 0,
    timestamp: '1652077753',
    sender: '0x5de81af11cc158ba45928278f61fa94f0e78047a',
    token0Symbol: 'ETH',
    token1Symbol: 'RPL',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xd33526068d116ce69f19a9ee46f0bd304f21a51f',
    amountUSD: 1333.8513537938913,
    amountToken0: -0.5478079670573328,
    amountToken1: 50,
  },
  {
    hash: '0x5dd6f0d9b19b3279d33f1b03bb0224be691664be9994e3d6402a685c830defdb',
    type: 0,
    timestamp: '1652077753',
    sender: '0x28cad4d9f9163ebbd2adf5164787d362f4b643ff',
    token0Symbol: 'LINK',
    token1Symbol: 'ETH',
    token0Address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5105.0819679139595,
    amountToken0: -529.6042567839613,
    amountToken1: 2.1,
  },
  {
    hash: '0x54efaeb8c2dd2a9d3631908264a0684cd4f2d80e275efe674df82504d3cb6e41',
    type: 0,
    timestamp: '1652077753',
    sender: '0x8f368be8c495ce0553eac35987f8f4bd370400d1',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1828.4125706023544,
    amountToken0: -1830.6587188373621,
    amountToken1: 0.75,
  },
  {
    hash: '0x1ea05ba7d50f9523ecd13e1b9182c7c8198fd8af6e60039e46918463d182e383',
    type: 0,
    timestamp: '1652077753',
    sender: '0xf38c60143b655a5d7b68b49c189da7cb2b0604a1',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 10412.411856727318,
    amountToken0: -10423.039831,
    amountToken1: 4.271975333840457,
  },
  {
    hash: '0x0de85b95d2286e0ce5f30261abeb7c2b7f8bcab379d2ad5c81a8819f4b52d615',
    type: 0,
    timestamp: '1652077753',
    sender: '0x81563cda0be02474362f7138dfc8de6a3e334558',
    token0Symbol: 'USDC',
    token1Symbol: 'UST',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xa693b19d2931d498c5b318df961919bb4aee87a5',
    amountUSD: 26512.830339,
    amountToken0: -26512.830339,
    amountToken1: 26686.1,
  },
  {
    hash: '0x0604ce602084bba5c17aac2cd9e5972ee3f422ff4fd5246521f76a57b0588000',
    type: 0,
    timestamp: '1652077753',
    sender: '0x31a7ca77c0bc7a26a5ee69ea3e65363ead6aa322',
    token0Symbol: 'HEX',
    token1Symbol: 'ETH',
    token0Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 11078.502147458306,
    amountToken0: 66322.67712609,
    amountToken1: -4.549901098182792,
  },
  {
    hash: '0x2dd4de50cab570a14a42a425fa70bf020cd5f7b0185c03afbe433bb6decb8a46',
    type: 0,
    timestamp: '1652077737',
    sender: '0x43e4715ae093a4c86b5ecddb52216c4f879e9672',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 105014.30061343408,
    amountToken0: 3.14947942,
    amountToken1: -43.11087063017465,
  },
  {
    hash: '0x2693729603e9adf902142768c4ecae010dbe7ea9052aa740e198a63068c13a1a',
    type: 0,
    timestamp: '1652077737',
    sender: '0xa5a13f62ce1113838e0d9b4559b8caf5f76463c0',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 183193.1844984605,
    amountToken0: 183463.893311,
    amountToken1: -75.125604700689,
  },
  {
    hash: '0x264407d86520b52ba3a11d5820c75060bae03512828863ff9ccf52f5a2fd3bd1',
    type: 0,
    timestamp: '1652077737',
    sender: '0x51963088c3bec10fabe76b72c8242c4e4b055f58',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 43874.019414408154,
    amountToken0: -43920.044692,
    amountToken1: 18,
  },
  {
    hash: '0x23189ae82796dd518cd8ca0608d2a0840de1bf124dfebaddbb79861e3f82aadd',
    type: 0,
    timestamp: '1652077737',
    sender: '0x3e420888caa03db71cffb774a114a9dc13b779f7',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 20882.575456351,
    amountToken0: 8.565692697929766,
    amountToken1: -20908.643727,
  },
  {
    hash: '0xcaa75e59235b97d5078b615b453dacdb3a06ea72e473b9e2cdaef7177229b529',
    type: 0,
    timestamp: '1652077718',
    sender: '0xd1e4a32679216f4a4dd38e45dab9bc4b8a45e592',
    token0Symbol: 'FTM',
    token1Symbol: 'ETH',
    token0Address: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 12791.712690566445,
    amountToken0: 20001.6,
    amountToken1: -5.253510523694743,
  },
  {
    hash: '0xbd56f6193e291ca61e7989db83777aa1ed23983cfb009c5a4e7c65fefd7afe8b',
    type: 0,
    timestamp: '1652077718',
    sender: '0x431b5a84acc1297eda88259f300262f1bc3a74f3',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 43421.91328894375,
    amountToken0: 43471.79175903706,
    amountToken1: -17.80418645601716,
  },
  {
    hash: '0xaf29fc04cb353b8ea9e31ee902e43020c675c352339756047319669c1478b20a',
    type: 0,
    timestamp: '1652077718',
    sender: '0xc5c451d023513d9cbda816ca93ffab058ca26950',
    token0Symbol: 'Metis',
    token1Symbol: 'ETH',
    token0Address: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 13003.506363179875,
    amountToken0: 316.2867149861241,
    amountToken1: -5.340493425425109,
  },
  {
    hash: '0xa725861e94963ef94887db9161242f8b0fb7af64b006358834121132001c3385',
    type: 0,
    timestamp: '1652077718',
    sender: '0x35fc3fad55c35e14dbe58257565374224545c2d6',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 88.83661024124719,
    amountToken0: -0.036417773619809135,
    amountToken1: 89,
  },
  {
    hash: '0x843642494d59634e4a5e68335875310b239fef61b8c14a797c2945928c389315',
    type: 0,
    timestamp: '1652077718',
    sender: '0x26bce6ecb5b10138e4bf14ac0ffcc8727fef3b2e',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 144332.93369994417,
    amountToken0: 144567.039563,
    amountToken1: -59.209221932216906,
  },
  {
    hash: '0x80676be40d191ea6041c98c66b4bc7a7c09f5184988c7cf3c2a88541b19035e5',
    type: 0,
    timestamp: '1652077718',
    sender: '0xbbad96336943a36e3e292d973b3382dd0ba4d1ef',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 53580.30328593992,
    amountToken0: -21.96931223354819,
    amountToken1: 53667.779474,
  },
  {
    hash: '0x78100b018f13baf86cdc5a909bbc93663216c51a4d49f3fa3e2d6c63a490cf89',
    type: 0,
    timestamp: '1652077718',
    sender: '0x76e40d0a69fd81826b5eb7d18145626d46eafdef',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 225771.35974350412,
    amountToken0: 6.77383465,
    amountToken1: -92.73588098507926,
  },
  {
    hash: '0x6bb462e1f07b0c89f3ec6ff7e21e45ef05050d4e2cdece5fb996f3423132f0ad',
    type: 0,
    timestamp: '1652077718',
    sender: '0x5fb27fdce1d062bf6239eeaf8f971cdfca23620f',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 3169.6422020517,
    amountToken0: 1.3,
    amountToken1: -3173.929272,
  },
  {
    hash: '0x51c2e4906d32ce8fe8fb5906bec922e1e62545d8cac2ca31929ad81fe5936aef',
    type: 0,
    timestamp: '1652077718',
    sender: '0xc1a80d351232fd07ee5733b5f581e01c269068a9',
    token0Symbol: 'MOONEY',
    token1Symbol: 'ETH',
    token0Address: '0x20d4db1946859e2adb0e5acc2eac58047ad41395',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 8117.157999671118,
    amountToken0: 328600,
    amountToken1: -3.3336876777426148,
  },
  {
    hash: '0x426b13293f7073a42d3c24dd7eda101f227e44ac82a7129097352c132d544dcf',
    type: 0,
    timestamp: '1652077718',
    sender: '0x93f635372008b7c5d770aaa6ff313454c8dc498c',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 163110.68617207618,
    amountToken0: -66.86404302097284,
    amountToken1: 163336.633498,
  },
  {
    hash: '0x3f4c94ee6497bb56d4297357ebb5d2e845a93bfe6132afd6615d547db0d184bb',
    type: 0,
    timestamp: '1652077718',
    sender: '0xe1a7775191a44db2cff011c7b6a0e6aef1a7255d',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 29581.44306957815,
    amountToken0: -12.133568076256084,
    amountToken1: 29619,
  },
  {
    hash: '0x2f8cbf7c5375a02c0794b34803862491e625dd0519a316ff217a564eb94a866f',
    type: 0,
    timestamp: '1652077718',
    sender: '0x9f64bfd4e6e9a3e92d913ad48a30d78058eab80d',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 34485.927622082476,
    amountToken0: 34533.039319,
    amountToken1: -14.143898183381452,
  },
  {
    hash: '0x2b28d97e4632561d126ca553a03c17edbda4fc04f3ea460223b33461be6595f9',
    type: 0,
    timestamp: '1652077718',
    sender: '0x6046945c5b5ef5933b8e73a98a6ad7bf3e031df7',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 305724.4283400919,
    amountToken0: 306164.703612,
    amountToken1: -125.43916867848199,
  },
  {
    hash: '0x0f72c6902387bd7906e01f369692dec26aaddeaa2aac62eb89da2ac19af4bddf',
    type: 0,
    timestamp: '1652077718',
    sender: '0xf27696c8bca7d54d696189085ae1283f59342fa6',
    token0Symbol: 'MATIC',
    token1Symbol: 'ETH',
    token0Address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 4953.103386815364,
    amountToken0: -5296.713588296898,
    amountToken1: 2.0375391007481682,
  },
  {
    hash: '0x038fd2dffdf9686bb32b3b6d086bb8b1056883891beb32f0c8ae157fe8816a44',
    type: 0,
    timestamp: '1652077718',
    sender: '0x0c7cad269192ff8a421f3529e27ce7d59c35521c',
    token0Symbol: 'UST',
    token1Symbol: 'UST',
    token0Address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    token1Address: '0xa693b19d2931d498c5b318df961919bb4aee87a5',
    amountUSD: 0,
    amountToken0: 11023.904572175337,
    amountToken1: -11031.635061,
  },
  {
    hash: '0x034f65e06ce41569fb2343818f4a492a9da6925c2b3353d01b26e9b2ea472fc2',
    type: 0,
    timestamp: '1652077718',
    sender: '0xc794e17e24e9f5da03a912d3dcb190064f6bf044',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 8773.08056168163,
    amountToken0: -8780.562296,
    amountToken1: 3.6,
  },
  {
    hash: '0xf66897c9805a5fb1e271131d13f1cff61696b3d918d88279a64705d23159bd17',
    type: 0,
    timestamp: '1652077690',
    sender: '0x4603180bbb8221157880afaa84638e0fc467738d',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 139404.5805320582,
    amountToken0: 4.18221935,
    amountToken1: -57.26536048122952,
  },
  {
    hash: '0xf09b16f1886e1872920b5f9b4979a6aee76881822ab496a0ff9c69c49b39c4db',
    type: 0,
    timestamp: '1652077671',
    sender: '0x43e4715ae093a4c86b5ecddb52216c4f879e9672',
    token0Symbol: 'ETH',
    token1Symbol: 'CRV',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xd533a949740bb3306d119cc777fa900ba034cd52',
    amountUSD: 16758.693337164874,
    amountToken0: -6.886032370904167,
    amountToken1: 7481.836369978919,
  },
  {
    hash: '0xe5dd9bf630265bf91ac5aaefdc9b837bc0bdef1b25c9ad1cf7ea4b646ec484cd',
    type: 0,
    timestamp: '1652077671',
    sender: '0x6c0cf880cb20eefabfb09341fba9e2bd29ad3dfa',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 34100.70504837491,
    amountToken0: -34129.292209,
    amountToken1: 14,
  },
  {
    hash: '0xe5dd9bf630265bf91ac5aaefdc9b837bc0bdef1b25c9ad1cf7ea4b646ec484cd',
    type: 0,
    timestamp: '1652077671',
    sender: '0x6c0cf880cb20eefabfb09341fba9e2bd29ad3dfa',
    token0Symbol: 'USDC',
    token1Symbol: 'USDT',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 34130.4608385,
    amountToken0: 34129.292209,
    amountToken1: -34131.629468,
  },
  {
    hash: '0xc1e687970c273d4dadff1b4c5f0756419c28c42bbead1fa8a0cbba42bb96217f',
    type: 0,
    timestamp: '1652077671',
    sender: '0x60b86af869f23aeb552fb7f3cabd11b829f6ab2f',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 94225.08746002673,
    amountToken0: 2.82665817,
    amountToken1: -38.70833894737317,
  },
  {
    hash: '0xb5502d1814ad8e92fb39c1762fec9b441431829cd388453dfd22fc6cdd0594cf',
    type: 0,
    timestamp: '1652077671',
    sender: '0xfa36355c65399c5a39a2cf89f6888e8c5b959fb3',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 7307.830901901767,
    amountToken0: 3,
    amountToken1: -7314.493685,
  },
  {
    hash: '0x7ca24c58e5932eb9d86f8988301f20ffb3f584db183e667e8d2c0f5452a69168',
    type: 0,
    timestamp: '1652077671',
    sender: '0x445950edb5c616c2af8efa5fdafbbaf7731993b9',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2592.4952139654406,
    amountToken0: -2594.554992,
    amountToken1: 1.064392187844341,
  },
  {
    hash: '0x5caac94f7005a75de50db01afd41c8ef9f4b831d35d1687704466ad6ef337c56',
    type: 0,
    timestamp: '1652077671',
    sender: '0xe3e249fa4a2465dd76c7cc9377e36ed15d004da3',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 9742.56982003569,
    amountToken0: -9750.248815,
    amountToken1: 4,
  },
  {
    hash: '0x5c009cba5a02559f05a52469a69265aa23b33de87e4ee9cfef7f0e55c67e740d',
    type: 0,
    timestamp: '1652077671',
    sender: '0x14d840e0b6a552da7f0337b30a0ba9528043cf8f',
    token0Symbol: 'Nvir',
    token1Symbol: 'ETH',
    token0Address: '0x9d71ce49ab8a0e6d2a1e7bfb89374c9392fd6804',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 8250.319974247994,
    amountToken0: -49706.97623629493,
    amountToken1: 3.39,
  },
  {
    hash: '0x2938eaa0493f8eae3bb5484ac1eb413aa6ef7526cc2e0239589cb9883698085e',
    type: 0,
    timestamp: '1652077671',
    sender: '0x8db03d7ed6f6a081c561c242412c98080e9fa13a',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 24356.915646839225,
    amountToken0: -24376.604231,
    amountToken1: 10,
  },
  {
    hash: '0x2938eaa0493f8eae3bb5484ac1eb413aa6ef7526cc2e0239589cb9883698085e',
    type: 0,
    timestamp: '1652077671',
    sender: '0x8db03d7ed6f6a081c561c242412c98080e9fa13a',
    token0Symbol: 'DAI',
    token1Symbol: 'USDC',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 24375.71615518292,
    amountToken0: -24374.828079365838,
    amountToken1: 24376.604231,
  },
  {
    hash: '0x0ffe45e3e7ebdfd8e9a2a265f4f825c7f0ba89a2e1f3e5d5a5968ef69c3af8ed',
    type: 0,
    timestamp: '1652077671',
    sender: '0xa5a13f62ce1113838e0d9b4559b8caf5f76463c0',
    token0Symbol: 'SHIB',
    token1Symbol: 'ETH',
    token0Address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 24189.002279412056,
    amountToken0: -1392974501.9410276,
    amountToken1: 9.939095451225954,
  },
  {
    hash: '0x0ee333a44fc72890731efae5f07f684aa8053740663f0ba4a79f385ededbd5e1',
    type: 0,
    timestamp: '1652077671',
    sender: '0x7f48432b9ed386db1ae4d351571f63ce670a80c7',
    token0Symbol: 'USDC',
    token1Symbol: 'UST',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xa693b19d2931d498c5b318df961919bb4aee87a5',
    amountUSD: 111769.459783,
    amountToken0: -111769.459783,
    amountToken1: 112500,
  },
  {
    hash: '0x0ee333a44fc72890731efae5f07f684aa8053740663f0ba4a79f385ededbd5e1',
    type: 0,
    timestamp: '1652077671',
    sender: '0x7f48432b9ed386db1ae4d351571f63ce670a80c7',
    token0Symbol: 'DAI',
    token1Symbol: 'USDC',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 111765.39231503558,
    amountToken0: -111761.32484707118,
    amountToken1: 111769.459783,
  },
  {
    hash: '0x0ee333a44fc72890731efae5f07f684aa8053740663f0ba4a79f385ededbd5e1',
    type: 0,
    timestamp: '1652077671',
    sender: '0x7f48432b9ed386db1ae4d351571f63ce670a80c7',
    token0Symbol: 'USDC',
    token1Symbol: 'UST',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xa693b19d2931d498c5b318df961919bb4aee87a5',
    amountUSD: 37280.075445,
    amountToken0: -37280.075445,
    amountToken1: 37500,
  },
  {
    hash: '0x0ee333a44fc72890731efae5f07f684aa8053740663f0ba4a79f385ededbd5e1',
    type: 0,
    timestamp: '1652077671',
    sender: '0x7f48432b9ed386db1ae4d351571f63ce670a80c7',
    token0Symbol: 'DAI',
    token1Symbol: 'USDC',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 37271.79095926176,
    amountToken0: -37263.506473523514,
    amountToken1: 37280.075445,
  },
  {
    hash: '0xf8238d85b7ef8649ba042b2aa3e315ef818ce02d65a5acb0d94a0f046a348541',
    type: 0,
    timestamp: '1652077657',
    sender: '0x524cfae2daab901234c842f3a17235902b0f01f9',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 4141.052032827668,
    amountToken0: -4144.775465,
    amountToken1: 1.7,
  },
  {
    hash: '0xf8238d85b7ef8649ba042b2aa3e315ef818ce02d65a5acb0d94a0f046a348541',
    type: 0,
    timestamp: '1652077657',
    sender: '0x524cfae2daab901234c842f3a17235902b0f01f9',
    token0Symbol: 'gOHM',
    token1Symbol: 'USDC',
    token0Address: '0x0ab87046fbb341d058f17cbc4c1133f25a20a52f',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 4144.775465,
    amountToken0: -1.892149744238153,
    amountToken1: 4144.775465,
  },
  {
    hash: '0xf14ce9eee511028c5fe699c26956d97000994ce347a704f41c76c42a5ddd9cd1',
    type: 0,
    timestamp: '1652077657',
    sender: '0xfcfe9ba9156d4e3690c35e04d4b7eb91aa177ac3',
    token0Symbol: 'UST',
    token1Symbol: 'UST',
    token0Address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    token1Address: '0xa693b19d2931d498c5b318df961919bb4aee87a5',
    amountUSD: 0,
    amountToken0: -4991.436361220924,
    amountToken1: 5000,
  },
  {
    hash: '0xf0bb579ff2c8ae75208317578956a11f4ff9ea71768634b7ff7c7b4d4045543c',
    type: 0,
    timestamp: '1652077657',
    sender: '0x8e8f818d3371f797a2db7edb32803607c8b3c6a9',
    token0Symbol: 'MANA',
    token1Symbol: 'ETH',
    token0Address: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 8326.935265917902,
    amountToken0: 6578.703915369459,
    amountToken1: -3.42148069887855,
  },
  {
    hash: '0xdcfc25b6ad0e7c54918bdc66c38721a26a87b99fdcb4c9d0ed8f87a2d07558ea',
    type: 0,
    timestamp: '1652077657',
    sender: '0x86964e67164841b0664fde758aa29d4097f66fc1',
    token0Symbol: 'UBT',
    token1Symbol: 'ETH',
    token0Address: '0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 201.03650393602948,
    amountToken0: -480,
    amountToken1: 0.08260452327550594,
  },
  {
    hash: '0xcb639605856945a7f2bf357819c901b5bdab132ff7b1e26f11b512d7d51c9de9',
    type: 0,
    timestamp: '1652077657',
    sender: '0x4603180bbb8221157880afaa84638e0fc467738d',
    token0Symbol: 'SHIB',
    token1Symbol: 'ETH',
    token0Address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 16430.11777383737,
    amountToken0: -948946143.2712679,
    amountToken1: 6.7510229211910655,
  },
  {
    hash: '0xb7347198bfa2d562853ccb3f9684419ad4f10bd07e2b2f250c53890c3a416dea',
    type: 0,
    timestamp: '1652077657',
    sender: '0xe89c36db37b1f5e3126f45a4a5c77d0f58c00765',
    token0Symbol: 'LYXe',
    token1Symbol: 'ETH',
    token0Address: '0xa8b919680258d369114910511cc87595aec0be6d',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 3163.839518148198,
    amountToken0: -386.6585082691581,
    amountToken1: 1.3,
  },
  {
    hash: '0xa1c29629f68016efa98a2a256e9a80c3b06834968eb1958fdf3b172c210dffea',
    type: 0,
    timestamp: '1652077657',
    sender: '0x23822299586a9a1ea54e3d183ac3cf5647a9b3de',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 40191.64924920972,
    amountToken0: -40226.873845,
    amountToken1: 16.5,
  },
  {
    hash: '0x5e92e1995814e07910e6519df17d9b38c9f32c54d417b3bd8efb8a4534ef359f',
    type: 0,
    timestamp: '1652077657',
    sender: '0xe92f359e6f05564849afa933ce8f62b8007a1d5d',
    token0Symbol: 'erowan',
    token1Symbol: 'USDT',
    token0Address: '0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 1351.819747,
    amountToken0: 10241.040283221726,
    amountToken1: -1351.819747,
  },
  {
    hash: '0x5e92e1995814e07910e6519df17d9b38c9f32c54d417b3bd8efb8a4534ef359f',
    type: 0,
    timestamp: '1652077657',
    sender: '0xe92f359e6f05564849afa933ce8f62b8007a1d5d',
    token0Symbol: 'ETH',
    token1Symbol: 'WINTER',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xccba0b2bc4babe4cbfb6bd2f1edc2a9e86b7845f',
    amountUSD: 1064.7938385042232,
    amountToken0: -0.4375164992141208,
    amountToken1: 24662.633856669403,
  },
  {
    hash: '0x5e92e1995814e07910e6519df17d9b38c9f32c54d417b3bd8efb8a4534ef359f',
    type: 0,
    timestamp: '1652077657',
    sender: '0xe92f359e6f05564849afa933ce8f62b8007a1d5d',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 1349.8653703940158,
    amountToken0: -0.5538474002467915,
    amountToken1: 1351.819747,
  },
  {
    hash: '0x5e92e1995814e07910e6519df17d9b38c9f32c54d417b3bd8efb8a4534ef359f',
    type: 0,
    timestamp: '1652077657',
    sender: '0xe92f359e6f05564849afa933ce8f62b8007a1d5d',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 1065.8052307521116,
    amountToken0: 0.4375164992141208,
    amountToken1: -1066.816623,
  },
  {
    type: 1,
    hash: '0x58a2f63a7e90aded065737c2f4a6a57e26f719b1b359bd697ff4b4300d579e91',
    timestamp: '1652077657',
    sender: '0xd730cd62cda9cfdc109be2d819b0337fafdca959',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 8369560.246064434,
    amountToken0: 130.57192642,
    amountToken1: 1650.190524192642,
  },
  {
    hash: '0x57e25f236f2ca19123b14c690637ab06da465c46c0d17db2542e3062b6bc9247',
    type: 0,
    timestamp: '1652077657',
    sender: '0x0f4ee9631f4be0a63756515141281a3e2b293bbe',
    token0Symbol: 'Metis',
    token1Symbol: 'ETH',
    token0Address: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 18432.143132249206,
    amountToken0: -447.0686825510708,
    amountToken1: 7.573641436133541,
  },
  {
    hash: '0x48c03d30c1c440bf252f2a69f730130eec6808f04ca467ade84ca76f9eea80f7',
    type: 0,
    timestamp: '1652077657',
    sender: '0xe8ac501bd83921fd492ff17c9b3f47073a186a5d',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 74.92926931379303,
    amountToken0: 0.030758861079281673,
    amountToken1: -75,
  },
  {
    hash: '0x37676d7ca72586179b1ce666934d7287d5828135d36e11f51f227bd9d142fb6b',
    type: 0,
    timestamp: '1652077657',
    sender: '0xf07704777d6bc182bf2c67fbda48913169b84983',
    token0Symbol: 'ETH',
    token1Symbol: 'ZRX',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xe41d2489571d322189246dafa5ebde1f4699f498',
    amountUSD: 3014.8801954014307,
    amountToken0: -1.2387936339817451,
    amountToken1: 5121.811287257094,
  },
  {
    hash: '0x1a3bc69ae64c629b01b9944aad32fa312d6c154300aa3315e7518b16255d6651',
    type: 0,
    timestamp: '1652077657',
    sender: '0xb0fe3d791038f52f369f4ce9eac43a883900f89d',
    token0Symbol: 'FRAX',
    token1Symbol: 'USDT',
    token0Address: '0x853d955acef822db058eb8505911ed77f175b99e',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 7140.975995,
    amountToken0: 7146.240218286421,
    amountToken1: -7140.975995,
  },
  {
    hash: '0x1a3bc69ae64c629b01b9944aad32fa312d6c154300aa3315e7518b16255d6651',
    type: 0,
    timestamp: '1652077657',
    sender: '0xb0fe3d791038f52f369f4ce9eac43a883900f89d',
    token0Symbol: 'FRAX',
    token1Symbol: 'FPIS',
    token0Address: '0x853d955acef822db058eb8505911ed77f175b99e',
    token1Address: '0xc2544a32872a91f4a553b404c6950e89de901fdb',
    amountUSD: 0,
    amountToken0: 7067.368553697508,
    amountToken1: -1595.1486795457308,
  },
  {
    hash: '0x1a3bc69ae64c629b01b9944aad32fa312d6c154300aa3315e7518b16255d6651',
    type: 0,
    timestamp: '1652077657',
    sender: '0xb0fe3d791038f52f369f4ce9eac43a883900f89d',
    token0Symbol: 'sUSD',
    token1Symbol: 'FRAX',
    token0Address: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
    token1Address: '0x853d955acef822db058eb8505911ed77f175b99e',
    amountUSD: 0,
    amountToken0: 7068.155664775061,
    amountToken1: -7067.369260434434,
  },
  {
    hash: '0xdc34979ee7b5d207c55d8303bedc1891457c837401903d49c5b50fa6aea0bc25',
    type: 0,
    timestamp: '1652077655',
    sender: '0x8349657449884c0d1c86f087fc17577c78ed0770',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1094.8337547382214,
    amountToken0: -0.0328268,
    amountToken1: 0.45,
  },
  {
    hash: '0x0dc4007ff17dda41edbf98554b9700b790d4b8a94f53df3204e29af1faa89427',
    type: 0,
    timestamp: '1652077655',
    sender: '0x26fd09c8b44af53df38a9bad41d5abc55a1786af',
    token0Symbol: 'Metis',
    token1Symbol: 'ETH',
    token0Address: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 4489.361373977881,
    amountToken0: -110.14972049830293,
    amountToken1: 1.8446478567241515,
  },
  {
    hash: '0xc309b30c51c23657350a0797ff7bd447a37e694b9900c493b8fd9e2a43652395',
    type: 0,
    timestamp: '1652077652',
    sender: '0xca2a1ac81bafa96267d892815f90a50caf96588e',
    token0Symbol: 'AAVE',
    token1Symbol: 'ETH',
    token0Address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 121.33820625684294,
    amountToken0: 1,
    amountToken1: -0.05000838336838416,
  },
  {
    hash: '0x9b24f00fe8f572f9f04d2de386e5392fc2d6374c700e29eb9862d13911b9ec4a',
    type: 0,
    timestamp: '1652077652',
    sender: '0x010f358dbf3050e854f50f9b8061f73bd84ac7ef',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 6503.923142367573,
    amountToken0: -6509.806659,
    amountToken1: 2.67,
  },
  {
    hash: '0x0f668a0e45e550174b7952794360c3c080fff4475ec82f1e965375d273605a3c',
    type: 0,
    timestamp: '1652077652',
    sender: '0xa3f2d88fb722d4f2a082edbf98354b81dc34829e',
    token0Symbol: 'MIM',
    token1Symbol: 'USDC',
    token0Address: '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 26136.731732,
    amountToken0: -26218.74815823897,
    amountToken1: 26136.731732,
  },
  {
    hash: '0xfc5f1e4e997e3e658d55677632cd96c83da1436f9f325cea9830b866a49efd84',
    type: 0,
    timestamp: '1652077622',
    sender: '0x26ccc3a2052be5898d60683c7bb621047153bb19',
    token0Symbol: 'Metis',
    token1Symbol: 'ETH',
    token0Address: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5037.382779810727,
    amountToken0: -124.19221969890388,
    amountToken1: 2.069826100910091,
  },
  {
    hash: '0xe73f8a7e2967833e6ea4335950c832085fe0150b64f531d826513c0d0b208631',
    type: 0,
    timestamp: '1652077622',
    sender: '0xb0fe3d791038f52f369f4ce9eac43a883900f89d',
    token0Symbol: 'EURT',
    token1Symbol: 'USDT',
    token0Address: '0xc581b735a1688071a1746c968e0798d642ede491',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 13824.437466,
    amountToken0: 13153.591618,
    amountToken1: -13824.437466,
  },
  {
    hash: '0xce409308e0ecc381a980c922e5219dcf23812b5f414c48d5011ada92eab91c3c',
    type: 0,
    timestamp: '1652077622',
    sender: '0x99d56af0d927352cb69b108f2e0e6cc525b54389',
    token0Symbol: 'JPG',
    token1Symbol: 'ETH',
    token0Address: '0x02e7ac540409d32c90bfb51114003a9e1ff0249c',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 60.84429310624855,
    amountToken0: -0.9829793164557631,
    amountToken1: 0.0250005035288323,
  },
  {
    hash: '0xbc5efaa5a45c3cf24a631ee18b9c37fffcf08cfd9425b5e9d485005470c79f3c',
    type: 0,
    timestamp: '1652077622',
    sender: '0x9caf2f699d62ef9602f5fc95d7f646d3c8659875',
    token0Symbol: 'MANA',
    token1Symbol: 'ETH',
    token0Address: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 9976.196742097838,
    amountToken0: -7838.838721683088,
    amountToken1: 4.099150949450813,
  },
  {
    hash: '0xbc5efaa5a45c3cf24a631ee18b9c37fffcf08cfd9425b5e9d485005470c79f3c',
    type: 0,
    timestamp: '1652077622',
    sender: '0x9caf2f699d62ef9602f5fc95d7f646d3c8659875',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 9988.098371048918,
    amountToken0: 10000,
    amountToken1: -4.099150949450813,
  },
  {
    hash: '0x73bcfb85bfee4750ad6ccca7d40824423cb8c545deee3a6429eff945c8426356',
    type: 0,
    timestamp: '1652077622',
    sender: '0x5f3dee897b846cbad0aa8ab907ca45e1987a1b96',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2605.23532767673,
    amountToken0: -2607.604221,
    amountToken1: 1.0695,
  },
  {
    hash: '0x7142b3032afefa03fbee54263200b2c9e2539923498874101e93c7c9f8ba6621',
    type: 0,
    timestamp: '1652077622',
    sender: '0x4603180bbb8221157880afaa84638e0fc467738d',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 167156.55404432764,
    amountToken0: -167323.621238,
    amountToken1: 68.614837023375,
  },
  {
    hash: '0x67d8a46f657b42e2ea29e5ac6769daab349fac531f1cf84b51235535640c64a4',
    type: 0,
    timestamp: '1652077622',
    sender: '0xecd5e98bd002708868f36bb6daa29e16f11b98c0',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 3044.930906417403,
    amountToken0: -3047.70843,
    amountToken1: 1.25,
  },
  {
    hash: '0x67790d0f826af9e2d9beda93f471fb9209546a6fb75a66be9b7e9e8d29e27a62',
    type: 0,
    timestamp: '1652077622',
    sender: '0xd9a409eb3980b585dffa8798dad57c695e20d0aa',
    token0Symbol: 'PLSD',
    token1Symbol: 'USDC',
    token0Address: '0x34f0915a5f15a66eba86f6a58be1a471fb7836a7',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 161.850939,
    amountToken0: -62.56050374673,
    amountToken1: 161.850939,
  },
  {
    hash: '0x5fe387dfddea4b3973c4ca0f2813967f144fd3d2a69aea111091729394a37a01',
    type: 0,
    timestamp: '1652077622',
    sender: '0x399a0b9de6621c9c6438808b420a375a0ad11bef',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 6648.756222056006,
    amountToken0: 0.19945141,
    amountToken1: -2.7314197260131365,
  },
  {
    hash: '0x5fe387dfddea4b3973c4ca0f2813967f144fd3d2a69aea111091729394a37a01',
    type: 0,
    timestamp: '1652077622',
    sender: '0x399a0b9de6621c9c6438808b420a375a0ad11bef',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 6651.936076493436,
    amountToken0: -6656.3539454408065,
    amountToken1: 2.7314197260131365,
  },
  {
    hash: '0x5fe387dfddea4b3973c4ca0f2813967f144fd3d2a69aea111091729394a37a01',
    type: 0,
    timestamp: '1652077622',
    sender: '0x399a0b9de6621c9c6438808b420a375a0ad11bef',
    token0Symbol: 'WBTC',
    token1Symbol: 'renBTC',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
    amountUSD: 6649.994236565945,
    amountToken0: -0.19945141,
    amountToken1: 0.1995,
  },
  {
    hash: '0x54466efb272e1f831419da2eb5d9b85f2d957894f3bd0d38c44d6d409692250a',
    type: 0,
    timestamp: '1652077622',
    sender: '0x26ccc3a2052be5898d60683c7bb621047153bb19',
    token0Symbol: 'Metis',
    token1Symbol: 'ETH',
    token0Address: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5037.382779810727,
    amountToken0: 120.89399420996283,
    amountToken1: -2.069826100910091,
  },
  {
    hash: '0x366d30b135182b934f39fbb5203b5682ff53be6305d9ffbcd0da24ea3082bcf4',
    type: 0,
    timestamp: '1652077622',
    sender: '0xb7df83c141917793fbf75f9ecaebf6983755fca6',
    token0Symbol: 'Metis',
    token1Symbol: 'ETH',
    token0Address: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 32707.040782184555,
    amountToken0: 800,
    amountToken1: -13.439099161934253,
  },
  {
    hash: '0x29b1126ce7243cafb4f346a061cf0db714ef7db9ac8affe5bb9622c624d5a1d4',
    type: 0,
    timestamp: '1652077622',
    sender: '0x79b7a69d90c82e014bf0315e164208119b510fa0',
    token0Symbol: 'SHIB',
    token1Symbol: 'ETH',
    token0Address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 37331.414562601596,
    amountToken0: 2165832329.2519646,
    amountToken1: -15.33922269223923,
  },
  {
    hash: '0xf8177fde5b99098b77ebd4f4ab6010f38006d8186a55310b293b531a160c62a6',
    type: 0,
    timestamp: '1652077592',
    sender: '0xdd77ac062f7365573a44f668337ab8d1b43c2691',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 48728.551564178444,
    amountToken0: -48782.649003,
    amountToken1: 20,
  },
  {
    hash: '0xc217fad77d752616c54b21e9b7b7777850e166efbd0ddfde3b42938f201a89c5',
    type: 0,
    timestamp: '1652077592',
    sender: '0x2fde904c015e507f7f9904122e437a7a2f95dc4d',
    token0Symbol: 'USDC',
    token1Symbol: 'MLP',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xe22020f47b7378dfedcedd2c81d4137c22fe1152',
    amountUSD: 3000,
    amountToken0: 3000,
    amountToken1: -227870.82732856408,
  },
  {
    hash: '0x5490054d6b6fd117a63768ef36347749b2e0d1c408050341d1f1342980afc4d3',
    type: 0,
    timestamp: '1652077592',
    sender: '0xe3d31424b62e999e63a2dd768e94ec32a9527f75',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 4531.8792163290955,
    amountToken0: -4537.034199,
    amountToken1: 1.86,
  },
  {
    type: 2,
    hash: '0xf47232253a7884c3aba78546bc26017bf5ffd753e3147ac32b7b0f950381f560',
    timestamp: '1652077547',
    sender: '0x51963088c3bec10fabe76b72c8242c4e4b055f58',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 133530.75315936643,
    amountToken0: 9597.265104,
    amountToken1: 50.92342185746402,
  },
  {
    hash: '0xa99ca80c66f8a99bfa730f699e0d8b804ce2dab012949124c9e25977050f167b',
    type: 0,
    timestamp: '1652077547',
    sender: '0xa5717849c18d1133594c2373df75ff0de720b29f',
    token0Symbol: 'USDC',
    token1Symbol: 'UST',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    amountUSD: 167619.213,
    amountToken0: 167619.213,
    amountToken1: -168523.733505673,
  },
  {
    hash: '0xa5d3e027b624da82defb5c2932d57bd5063eadb94b212d77431a348ccf51ea9f',
    type: 0,
    timestamp: '1652077547',
    sender: '0x573af77214df6bccaed3b0859a60170f2071641e',
    token0Symbol: 'HEX',
    token1Symbol: 'USDC',
    token0Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 16789.066876,
    amountToken0: -100000,
    amountToken1: 16789.066876,
  },
  {
    hash: '0x87071b10aa07d68b1c69bd47a4c29a4bc88e9b2ad92a95d74babe43d4f396686',
    type: 0,
    timestamp: '1652077547',
    sender: '0x33f0db5f5143e5c222c1fa47701143fc9abc8b15',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 2213.2854639665547,
    amountToken0: 0.9085585511604941,
    amountToken1: -2215.391352,
  },
  {
    hash: '0x7b0c24b525666cccea0194518215af7b88a0ab7082743e494249e3c7d5c0f5d1',
    type: 0,
    timestamp: '1652077547',
    sender: '0x839d4641f97153b0ff26ab837860c479e2bd0242',
    token0Symbol: 'GHST',
    token1Symbol: 'ETH',
    token0Address: '0x3f382dbd960e3a9bbceae22651e88158d2791550',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1352.0480017918446,
    amountToken0: -768.7857452358427,
    amountToken1: 0.5555472685157437,
  },
  {
    hash: '0x6836b220557a75a0361c6c46d400361e42bc710f1e81a6059c0118b565aa4749',
    type: 0,
    timestamp: '1652077547',
    sender: '0x9b3dc230bc76c2af3dffef82ea3b344bf41df465',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 890.931975953495,
    amountToken0: 892.011277697952,
    amountToken1: -0.36563437236185475,
  },
  {
    hash: '0x61f7a02bffc84e8bcc44bbbe538c49fc13f325b0dcb3d4f5a6e38999aad2fa3f',
    type: 0,
    timestamp: '1652077547',
    sender: '0xc5584d60f62ae97fec2afba66d47a36ec4ff747c',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 9983.617083622565,
    amountToken0: 10000,
    amountToken1: -4.095468288796981,
  },
  {
    hash: '0x5e63dd5b59dfe5ade4fa12dd00523828dc669d055dca0fdee590eac19d07ee70',
    type: 0,
    timestamp: '1652077547',
    sender: '0x5f3dee897b846cbad0aa8ab907ca45e1987a1b96',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5969.44363117811,
    amountToken0: -5976.266632,
    amountToken1: 2.45,
  },
  {
    hash: '0x583d567c95b1e9456fd57c17f20e2ac0c9c734a41a228fce2ddfce25ac4466d1',
    type: 0,
    timestamp: '1652077547',
    sender: '0x81b9fc08d57f2b61ad9c89b20f6a4d6127197b2e',
    token0Symbol: 'SHIB',
    token1Symbol: 'ETH',
    token0Address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 21171.5402578649,
    amountToken0: 1223143716.6012754,
    amountToken1: -8.699240962554777,
  },
  {
    type: 1,
    hash: '0x5730edddb6335e2e94197b103e496dbd88a62387705d4d5e9c5cb28981229dc5',
    timestamp: '1652077547',
    sender: '0xf540467a6d76b4133be6f764d517e24d68511cbc',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 4961692.544821573,
    amountToken0: 1167704.788222,
    amountToken1: 1558.9235975111226,
  },
  {
    hash: '0x510d56b1ff3f7f9627c4b23024abc490a4c7df611843c83db80bd18699ae9ef7',
    type: 0,
    timestamp: '1652077547',
    sender: '0xca6dc73b9a12d86a949703784614956c351b06d8',
    token0Symbol: 'USDC',
    token1Symbol: 'UST',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    amountUSD: 166685.511371,
    amountToken0: -166685.511371,
    amountToken1: 167619.21300098,
  },
  {
    hash: '0x4b990a3ae60c2b844a66aaebec41f680f7e977331397a31ee426ca0cf68addc4',
    type: 0,
    timestamp: '1652077547',
    sender: '0x43e4715ae093a4c86b5ecddb52216c4f879e9672',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 185960.36419282298,
    amountToken0: 186246.170481,
    amountToken1: -76.29240481113851,
  },
  {
    hash: '0x44d6635cbbd43ceeec610d04ca093ec64e4d1d27ea37f598eb942bb7535e66b6',
    type: 0,
    timestamp: '1652077547',
    sender: '0x361142e8f48ba59496e3cb0029d1f30c1c6845ef',
    token0Symbol: 'MRI',
    token1Symbol: 'HEX',
    token0Address: '0x0913ddae242839f8995c0375493f9a1a3bddc977',
    token1Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    amountUSD: 0,
    amountToken0: 4227.537711760093,
    amountToken1: -749.26885597,
  },
  {
    hash: '0x3fae9bc9e1e1e1ceb34ecb0f9c43f2205cc53cbfb94285464330d4f164b91967',
    type: 0,
    timestamp: '1652077547',
    sender: '0x1a159b9ad4869c430888ee1b67a082fcb40e8f67',
    token0Symbol: 'POOL',
    token1Symbol: 'ETH',
    token0Address: '0x0cec1a9154ff802e7934fc916ed7ca50bde6844e',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 13.273663002952615,
    amountToken0: -10,
    amountToken1: 0.005454057263289458,
  },
  {
    hash: '0x38731b5a303214699661470fdad337b200d40fccf91d51693e75a041e89dda94',
    type: 0,
    timestamp: '1652077547',
    sender: '0x83cce5c4e75fd4eaa7fedeb92d6464881307d38f',
    token0Symbol: 'ETH',
    token1Symbol: 'LOOKS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    amountUSD: 1207.2383174443837,
    amountToken0: 0.496045960509488,
    amountToken1: -1006.1037861675976,
  },
  {
    hash: '0x0a4fff59d191dd60bff8508c396b28e0d22b77225966683017f154371fc445de',
    type: 0,
    timestamp: '1652077547',
    sender: '0x691b3925e45366419afef3480910118a7a2e2a1d',
    token0Symbol: 'APE',
    token1Symbol: 'USDC',
    token0Address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 7167.714643,
    amountToken0: -638.0223701479682,
    amountToken1: 7167.714643,
  },
  {
    hash: '0x0536c183bef755908707de92febc41a219a794c41b1cc0f73135948b69ba7e57',
    type: 0,
    timestamp: '1652077547',
    sender: '0x6046945c5b5ef5933b8e73a98a6ad7bf3e031df7',
    token0Symbol: 'LINK',
    token1Symbol: 'ETH',
    token0Address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 12569.931433911857,
    amountToken0: -1305.7415277484884,
    amountToken1: 5.173941649669468,
  },
  {
    hash: '0x7c22fb6397647ce73d0dfecdfc7fb4fc601e39b86d6818c725f7f47249fcec56',
    type: 0,
    timestamp: '1652077544',
    sender: '0x445950edb5c616c2af8efa5fdafbbaf7731993b9',
    token0Symbol: 'USDC',
    token1Symbol: 'USDT',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 1493.508233,
    amountToken0: -1493.31197,
    amountToken1: 1493.704496,
  },
  {
    hash: '0x6abcf787a6212f863705d640ffecaed2b3617d230c1fdec9aea8ae4c7b6e2fc7',
    type: 0,
    timestamp: '1652077544',
    sender: '0x173107421523f1e469372e0529b64eeb49616fca',
    token0Symbol: 'ETH',
    token1Symbol: 'FLOOR',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf59257e961883636290411c11ec5ae622d19455e',
    amountUSD: 634.5108137102214,
    amountToken0: -0.2607161498210512,
    amountToken1: 50.358347837,
  },
  {
    hash: '0x66e6fa06471ddd348f0c3d3944ce6e1e09d30590684d44f16a65225bbc2417a5',
    type: 0,
    timestamp: '1652077544',
    sender: '0x0c7cad269192ff8a421f3529e27ce7d59c35521c',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 10962.098866102651,
    amountToken0: -10972.445554,
    amountToken1: 4.5,
  },
  {
    hash: '0x66e6fa06471ddd348f0c3d3944ce6e1e09d30590684d44f16a65225bbc2417a5',
    type: 0,
    timestamp: '1652077544',
    sender: '0x0c7cad269192ff8a421f3529e27ce7d59c35521c',
    token0Symbol: 'USDC',
    token1Symbol: 'UST',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    amountUSD: 10972.445554,
    amountToken0: 10972.445554,
    amountToken1: -11023.904572175337,
  },
  {
    hash: '0x3d96f3b435aaa670717c6374cf7ece8189b3cfcfc15169f11695cc97e1be4807',
    type: 0,
    timestamp: '1652077544',
    sender: '0xff82bf5238637b7e5e345888bab9cd99f5ebe331',
    token0Symbol: 'APE',
    token1Symbol: 'ETH',
    token0Address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 39894.54182703595,
    amountToken0: -3574.8204050632116,
    amountToken1: 16.39239413935325,
  },
  {
    hash: '0x329e73d0b24918a8c4899abbb65225411876e32b77c34486984b5125dc2a4397',
    type: 0,
    timestamp: '1652077544',
    sender: '0x6f93428716dbc41bda6069fcca98ec105cb98168',
    token0Symbol: 'GAL',
    token1Symbol: 'ETH',
    token0Address: '0x5faa989af96af85384b8a938c2ede4a7378d9875',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 4980.687003351387,
    amountToken0: -516.4685037620893,
    amountToken1: 2.046530194472876,
  },
  {
    hash: '0xf7ccfbfde7348c9913308851fe7f1c7e39eea03ff754f2797348166ee96c1cbc',
    type: 0,
    timestamp: '1652077512',
    sender: '0xac221503406190103b8cb7d9393ae66d9c9af6a3',
    token0Symbol: 'APE',
    token1Symbol: 'ETH',
    token0Address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 58708.4334742084,
    amountToken0: -5272.0829788950605,
    amountToken1: 24.13517845838644,
  },
  {
    hash: '0xf604ae9a8f1fc90f46cb7f018edcecd5b194a86955a8b862ab670b274f2ca2c9',
    type: 0,
    timestamp: '1652077512',
    sender: '0x62908d30cc092361af24790d01b70833d0fdd2da',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 340.0689207958887,
    amountToken0: -0.1395054336787749,
    amountToken1: 340.6203,
  },
  {
    hash: '0xe454d75e5d24fc961070cee519084f3a3d687ac2e1c4d88e4443e8a0807e4f1f',
    type: 0,
    timestamp: '1652077512',
    sender: '0x065e3dbafcb2c26a978720f9eb4bce6ad9d644a1',
    token0Symbol: 'APE',
    token1Symbol: 'USDC',
    token0Address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 9302.419677,
    amountToken0: -832.3858849926329,
    amountToken1: 9302.419677,
  },
  {
    hash: '0xa9c90253116ef4967953ad5c3a7b696aab100fe467c4c9b61c0741a86c80900c',
    type: 0,
    timestamp: '1652077512',
    sender: '0xd57655b140ddfe390b15062d16dbb4c8bf02bf14',
    token0Symbol: 'RWASTE',
    token1Symbol: 'ETH',
    token0Address: '0x5cd2fac9702d68dde5a94b1af95962bcfb80fc7d',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 260.637882277706,
    amountToken0: 88.28250506846594,
    amountToken1: -0.10709432163592648,
  },
  {
    hash: '0xa13405dce5d6c6ee87a2d3f297b118890599816e9c5f26e344863b2c1df5d8d5',
    type: 0,
    timestamp: '1652077512',
    sender: '0x78bf83b63d920b3611881029925fa1a4f88072c7',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1255.6556794728804,
    amountToken0: -1256.84899,
    amountToken1: 0.51545,
  },
  {
    hash: '0x96b2c3d0524c2fb27dcd2327617bbff785696d10479f8209937b0c91907cdd6f',
    type: 0,
    timestamp: '1652077512',
    sender: '0x008b3b2f992c0e14edaa6e2c662bec549caa8df1',
    token0Symbol: 'GNO',
    token1Symbol: 'ETH',
    token0Address: '0x6810e776880c02933d47db1b9fc05908e5386b96',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1968.213434833055,
    amountToken0: -7.369216196502572,
    amountToken1: 0.808725427002875,
  },
  {
    hash: '0x81a0ff67711b190bc243b9cc1a9ac98f501c98af42972ae0c0ed3e875d71bf06',
    type: 0,
    timestamp: '1652077512',
    sender: '0xa5a13f62ce1113838e0d9b4559b8caf5f76463c0',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 153655.001351921,
    amountToken0: 153905.387985,
    amountToken1: -63.06500673716348,
  },
  {
    hash: '0x7fbfa610b3c746aae6aa41decc2ac40d135dd944e4fd02824f4e44655a327f82',
    type: 0,
    timestamp: '1652077512',
    sender: '0x93f635372008b7c5d770aaa6ff313454c8dc498c',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 167201.81933986448,
    amountToken0: 167473.77038,
    amountToken1: -68.62527107317315,
  },
  {
    hash: '0x7e4b040e6780ce3933a3344cf0ab8c6f039460276787874c53dbe37ce8c2f249',
    type: 0,
    timestamp: '1652077512',
    sender: '0x3a3297881e9904d0463fec7acd9d6d34b915dcb7',
    token0Symbol: 'GAL',
    token1Symbol: 'ETH',
    token0Address: '0x5faa989af96af85384b8a938c2ede4a7378d9875',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 3812.56988362551,
    amountToken0: 400,
    amountToken1: -1.56655886630246,
  },
  {
    hash: '0x7e4b040e6780ce3933a3344cf0ab8c6f039460276787874c53dbe37ce8c2f249',
    type: 0,
    timestamp: '1652077512',
    sender: '0x3a3297881e9904d0463fec7acd9d6d34b915dcb7',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 3816.206889312755,
    amountToken0: -3819.843895,
    amountToken1: 1.56655886630246,
  },
  {
    hash: '0x6e928f2193cc5c1099ff9141d67c48699101b1ffcc33f33cb7985f7358970288',
    type: 0,
    timestamp: '1652077512',
    sender: '0xa5e24801f6fa2685a1cef37f388e1aae77c37aa3',
    token0Symbol: 'AXS',
    token1Symbol: 'ETH',
    token0Address: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2989.722437515207,
    amountToken0: 102.39726027397109,
    amountToken1: -1.2284564834832796,
  },
  {
    hash: '0x604ae4374315418831cbcb2d9a6be88cd0e040f2dda592b27c240f9f804f1cc6',
    type: 0,
    timestamp: '1652077512',
    sender: '0xb2b1b37eb5986dc9ee424e2fcd3a7bd6bcfcf3d2',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5215.839619695041,
    amountToken0: -5220.835553,
    amountToken1: 2.1411,
  },
  {
    hash: '0x59dbede6e41d792d2b60e95fd558471685a1c641d70d6e7d6837c2c3523b9e55',
    type: 0,
    timestamp: '1652077512',
    sender: '0xeca2e2d894d19778939bd4dfc34d2a3c45e96456',
    token0Symbol: 'DYDX',
    token1Symbol: 'ETH',
    token0Address: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 10841.328924069026,
    amountToken0: -2921.985345484458,
    amountToken1: 4.454627840775825,
  },
  {
    hash: '0x356b9e8b7183916d11cdb97b666bb89226563e8791139b017bc2926523b35995',
    type: 0,
    timestamp: '1652077512',
    sender: '0x85befb8f23472d3f3c01afd171e84bfdd61b29c6',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 1939.6707587207725,
    amountToken0: 0.7960217720240086,
    amountToken1: -1941.056737,
  },
  {
    hash: '0x2cb72d5fec2f4275ccc105ae138915d4464b9f7dfa34470a55ea83677be95117',
    type: 0,
    timestamp: '1652077512',
    sender: '0x431b5a84acc1297eda88259f300262f1bc3a74f3',
    token0Symbol: 'WBTC',
    token1Symbol: 'USDC',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 33156.26437367728,
    amountToken0: -0.99332898,
    amountToken1: 33193.525016,
  },
  {
    hash: '0x24cfb64d9eedd7857615c4c90185fb083437d539892f6e3b956cc62a97dc0a07',
    type: 0,
    timestamp: '1652077512',
    sender: '0x77eb9084ce8168901bcb11f14f02a7aab36fcd0c',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 82655.120192344,
    amountToken0: 82748.26649021116,
    amountToken1: -33.897831905278636,
  },
  {
    hash: '0x006f1a4ce85816bb7240ad07f544274501fe44410bc684de57f1902f208dd56d',
    type: 0,
    timestamp: '1652077512',
    sender: '0x6955224b1075f2e8d93d9878e3af95039b2509a0',
    token0Symbol: 'HEX',
    token1Symbol: 'ETH',
    token0Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 844.3496714058003,
    amountToken0: -5023.83801372,
    amountToken1: 0.3469375,
  },
  {
    hash: '0xe4fbb94c7634df8c3d95ccc60a2e553e18c1b835628489042e728b97ed264d8d',
    type: 0,
    timestamp: '1652077489',
    sender: '0x23e85c1d8bfcd8bfde5128f6d47d671a3e52d359',
    token0Symbol: 'PERP',
    token1Symbol: 'ETH',
    token0Address: '0xbc396689893d065f41bc2c6ecbee5e0085233447',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 7422.777695984459,
    amountToken0: 3555.023923444935,
    amountToken1: -3.051521795896991,
  },
  {
    hash: '0xe1a140d55f3b54e28e1562327141489734cd609ddd6dd51afe21db9dd999daf4',
    type: 0,
    timestamp: '1652077489',
    sender: '0x578bd725e7ac96d8b26c82e2c8daa843f7ad1226',
    token0Symbol: 'SHI',
    token1Symbol: 'ETH',
    token0Address: '0x243cacb4d5ff6814ad668c3e225246efa886ad5a',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2943.706237807921,
    amountToken0: 5098002136.264962,
    amountToken1: -1.2101647271813445,
  },
  {
    hash: '0x9bf4e21dd2ef2a988905b9c7a95a9214e38c37be436818da5e5f33c335258f44',
    type: 0,
    timestamp: '1652077489',
    sender: '0x368dec1eb6e533119f8740c15613144bf5a668ca',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 79845.40051873904,
    amountToken0: 79970.63039,
    amountToken1: -32.78182730506267,
  },
  {
    hash: '0x995058b8cb5d25bcc8d8d34d76bc22cde87f49b567c7cd0c0508b3ecf0215a8e',
    type: 0,
    timestamp: '1652077489',
    sender: '0xae545300740d789791e998c2e655fdafe6341690',
    token0Symbol: 'ETH',
    token1Symbol: 'LOOKS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    amountUSD: 5365.546100225255,
    amountToken0: -2.205788930009975,
    amountToken1: 4500.373700280397,
  },
  {
    hash: '0x898a4433b62dfe4df94f9fb9a1c818a6a0b0e4216b2f9c926b43a6cff7a79bc1',
    type: 0,
    timestamp: '1652077489',
    sender: '0x815cda45fc7ca4e3aecb91b13e3d2455e105aeb5',
    token0Symbol: 'HEX',
    token1Symbol: 'ETH',
    token0Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1101.918255761522,
    amountToken0: -6560.45915457,
    amountToken1: 0.45300125,
  },
  {
    hash: '0x874edc22bbab1875357c9b15735b9179fbda97714fe5df44725b212232e78044',
    type: 0,
    timestamp: '1652077489',
    sender: '0xd1c442e9e2de99d95036bb13ec07c94c6fb8fc03',
    token0Symbol: 'ETH',
    token1Symbol: 'PSTAKE',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xfb5c6815ca3ac72ce9f5006869ae67f18bf77006',
    amountUSD: 447.3979675598886,
    amountToken0: 0.1839263824629396,
    amountToken1: -1000,
  },
  {
    hash: '0x613a0a88d296fdbe4a0ad36c1d312873b4c15937bbda7489043f9a285bdb139d',
    type: 0,
    timestamp: '1652077489',
    sender: '0x69181a03fd84d1e2679eb520dfde72c97e1ce524',
    token0Symbol: 'WBTC',
    token1Symbol: 'USDC',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 37372.88463649323,
    amountToken0: -1.120206,
    amountToken1: 37415.516014,
  },
  {
    hash: '0x1ea49d41509205a0cca9e68b03f5b07dea3c501b786921e560f47ccbdaf198e0',
    type: 0,
    timestamp: '1652077489',
    sender: '0xb2b9300475af157676c44ee64d39a5eb3c294dbd',
    token0Symbol: 'LDO',
    token1Symbol: 'ETH',
    token0Address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 24.324839186680435,
    amountToken0: -8.900253523701547,
    amountToken1: 0.01,
  },
  {
    hash: '0x0f1f81cd58e839692f1bb270a101f002667db52710523659dad0b394bf349fb5',
    type: 0,
    timestamp: '1652077489',
    sender: '0x9aab3f81604c683a1a0d14019fbfe15bef7aa1ee',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 37060.10487880562,
    amountToken0: -15.207767217169241,
    amountToken1: 37127.560563,
  },
  {
    hash: '0x0de6a0cf100e3a436a674dea441f73f048aca783c05c9917ece0cb5cdcb53177',
    type: 0,
    timestamp: '1652077489',
    sender: '0x17ee27c30b91c5bcf3eae0f933453f5abf5cf099',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 94880.21052919142,
    amountToken0: -38.937,
    amountToken1: 95021.648247,
  },
  {
    hash: '0xff186d2316e78945f9fdf778128ea890eefe9e3b43f4d5ef917362f1abedf431',
    type: 0,
    timestamp: '1652077459',
    sender: '0x4425c20d8e84c48aebeb116cf7b9838d4833f78d',
    token0Symbol: 'USDC',
    token1Symbol: 'USDT',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 45838.488934,
    amountToken0: -45832.779804,
    amountToken1: 45844.198064,
  },
  {
    hash: '0xff186d2316e78945f9fdf778128ea890eefe9e3b43f4d5ef917362f1abedf431',
    type: 0,
    timestamp: '1652077459',
    sender: '0x4425c20d8e84c48aebeb116cf7b9838d4833f78d',
    token0Symbol: 'EURT',
    token1Symbol: 'USDT',
    token0Address: '0xc581b735a1688071a1746c968e0798d642ede491',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 45844.656513,
    amountToken0: 43571.407212,
    amountToken1: -45844.656513,
  },
  {
    hash: '0xff186d2316e78945f9fdf778128ea890eefe9e3b43f4d5ef917362f1abedf431',
    type: 0,
    timestamp: '1652077459',
    sender: '0x4425c20d8e84c48aebeb116cf7b9838d4833f78d',
    token0Symbol: 'agEUR',
    token1Symbol: 'USDC',
    token0Address: '0x1a7e4e63778b4f12a199c062f3efdd288afcbce8',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 45779.792794,
    amountToken0: -43730.57884624453,
    amountToken1: 45779.792794,
  },
  {
    hash: '0xf4350eaf34072df85880284b998084727ddb5a90227b53a61aff11d377ae7b1b',
    type: 0,
    timestamp: '1652077459',
    sender: '0xe1997c479a35ca8f6e3a5343ff866490b63debcf',
    token0Symbol: 'LINK',
    token1Symbol: 'ETH',
    token0Address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 6835.089731666275,
    amountToken0: -711.1559046970071,
    amountToken1: 2.81576021,
  },
  {
    hash: '0xf0d324ad92963625856972571ea61d74f244809037849afc84588b3d6ee88d3f',
    type: 0,
    timestamp: '1652077459',
    sender: '0x065e3dbafcb2c26a978720f9eb4bce6ad9d644a1',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 508795.73231968156,
    amountToken0: 15.27438442,
    amountToken1: -209.26269153593876,
  },
  {
    hash: '0xdd2c4c2e90d8ab35105cdd850f256e95c3d37da478f412cf2f379a4f1c4d7740',
    type: 0,
    timestamp: '1652077459',
    sender: '0xf540467a6d76b4133be6f764d517e24d68511cbc',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1126309.3131078614,
    amountToken0: -1126000,
    amountToken1: 463.3591686983453,
  },
  {
    hash: '0xb94368066eddb1d760a05efad51b01af7d4c48df2c9fe62bca621071dd35720c',
    type: 0,
    timestamp: '1652077459',
    sender: '0x8e2400a8822fe2da5a8c52b7f7b412acb49813c8',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 52728.66222974258,
    amountToken0: 52810.177697,
    amountToken1: -21.65288021213124,
  },
  {
    hash: '0x8e895285a3790c9844959913c9173b72d8b6331be7d57b10e94348f9ba4e633f',
    type: 0,
    timestamp: '1652077459',
    sender: '0x403c8510f7750fe1d3fe2f6c5055f318c005ad59',
    token0Symbol: 'NEXO',
    token1Symbol: 'ETH',
    token0Address: '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1793.6223898375404,
    amountToken0: 1000,
    amountToken1: -0.7375576213221277,
  },
  {
    hash: '0x8e895285a3790c9844959913c9173b72d8b6331be7d57b10e94348f9ba4e633f',
    type: 0,
    timestamp: '1652077459',
    sender: '0x403c8510f7750fe1d3fe2f6c5055f318c005ad59',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 1796.245207578018,
    amountToken0: 0.7375576213221277,
    amountToken1: -1798.55369,
  },
  {
    hash: '0x6b9f97b0eb71a40d38a51b1588f29fac13d4391d22a7638b5f80cc95ac2a4fe1',
    type: 0,
    timestamp: '1652077459',
    sender: '0x250f782f513b84e478d01ff7e860847e10ca7c5a',
    token0Symbol: 'MATIC',
    token1Symbol: 'ETH',
    token0Address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 9.33836117709438,
    amountToken0: -10,
    amountToken1: 0.003845808379790417,
  },
  {
    hash: '0x6478a73e88fc7c66a35589be3a1d0ebdf786d35aa6dca1d034985b6c56de7c03',
    type: 0,
    timestamp: '1652077459',
    sender: '0x5e2b6c6b2240d582995537d3fafdb556e4a3822f',
    token0Symbol: 'SPELL',
    token1Symbol: 'ETH',
    token0Address: '0x090185f2135308bad17527004364ebcc2d37e5f6',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5086.300644612949,
    amountToken0: -1775982.8292751769,
    amountToken1: 2.0919093503309805,
  },
  {
    hash: '0x56c80c8b2c7a16f747f4519924b83d8c8660a60321303ad5768ab5e587c5b3fa',
    type: 0,
    timestamp: '1652077459',
    sender: '0x36a454aef52938c8637cd4689b2980c1cfd43389',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 53184.88462836657,
    amountToken0: 53250.98423504636,
    amountToken1: -21.84685704388669,
  },
  {
    hash: '0x471547b82a1945f82ddc234fc254a0e4cfb26a3d6983c1d3adffe76ec76ab85a',
    type: 0,
    timestamp: '1652077459',
    sender: '0x431b5a84acc1297eda88259f300262f1bc3a74f3',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1740463.0925025314,
    amountToken0: 1741545.660623,
    amountToken1: -715.3777641107819,
  },
  {
    hash: '0x4271b792753bb915fda01787f8ac487d6d8ea269adc39bebeb1212e7ed5f4ead',
    type: 0,
    timestamp: '1652077459',
    sender: '0x2c177735792af1f07b37fc44fb875c18be3fb4fc',
    token0Symbol: 'USDC',
    token1Symbol: 'USDT',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 74442.188255,
    amountToken0: -74432.603454,
    amountToken1: 74451.773056,
  },
  {
    hash: '0x4271b792753bb915fda01787f8ac487d6d8ea269adc39bebeb1212e7ed5f4ead',
    type: 0,
    timestamp: '1652077459',
    sender: '0x2c177735792af1f07b37fc44fb875c18be3fb4fc',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 74311.43956172267,
    amountToken0: 74432.603454,
    amountToken1: -30.507872536618656,
  },
  {
    hash: '0x233216360c96763eafd44035c5adc3689609e215b40a3cd4fd86ebe9000103b3',
    type: 0,
    timestamp: '1652077459',
    sender: '0x2489ac126934d4d6a94df08743da7b7691e9798e',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 10667.946004002843,
    amountToken0: 10684.323975636016,
    amountToken1: -4.379276248903348,
  },
  {
    hash: '0x137a54a26e47fe537df1d44d05277e0236763afb13a7b364f7506d78f2d33cec',
    type: 0,
    timestamp: '1652077459',
    sender: '0xfb9779477e5b4834bf2bc02dd29b97b344d0f700',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 114538.33632367216,
    amountToken0: 114691.33411387508,
    amountToken1: -47.044753336075665,
  },
  {
    hash: '0x1159e124892401dbdef63eeea6bcffcce2c977c14a6754771433573eaa395315',
    type: 0,
    timestamp: '1652077459',
    sender: '0xeca2e2d894d19778939bd4dfc34d2a3c45e96456',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 126022.46502490398,
    amountToken0: -51.75745292698412,
    amountToken1: 126201.061077,
  },
  {
    hash: '0x0229fcfd5018ad9af195130e12f62a248c94a6bb8e5f814678f5e566ff6d3bb7',
    type: 0,
    timestamp: '1652077459',
    sender: '0xf07704777d6bc182bf2c67fbda48913169b84983',
    token0Symbol: 'MATIC',
    token1Symbol: 'ETH',
    token0Address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 22845.56790578836,
    amountToken0: 24500.057665396293,
    amountToken1: -9.376605628837247,
  },
  {
    hash: '0xf000ab2a7845ee4f467346fc907daa595696614e9f185bc952117fafbce59727',
    type: 0,
    timestamp: '1652077453',
    sender: '0x1ed9c7c5616f01d868a8f7eb4ce4c7c65f5df2aa',
    token0Symbol: 'HEX',
    token1Symbol: 'USDC',
    token0Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 486.764537,
    amountToken0: -2885.57011575,
    amountToken1: 486.764537,
  },
  {
    hash: '0xf000ab2a7845ee4f467346fc907daa595696614e9f185bc952117fafbce59727',
    type: 0,
    timestamp: '1652077453',
    sender: '0x1ed9c7c5616f01d868a8f7eb4ce4c7c65f5df2aa',
    token0Symbol: 'MAXI',
    token1Symbol: 'HEX',
    token0Address: '0x0d86eb9f43c57f6ff3bc9e23d8f9d82503f0e84b',
    token1Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    amountUSD: 0,
    amountToken0: -1960.78666069,
    amountToken1: 2885.57011575,
  },
  {
    hash: '0xf000ab2a7845ee4f467346fc907daa595696614e9f185bc952117fafbce59727',
    type: 0,
    timestamp: '1652077453',
    sender: '0x1ed9c7c5616f01d868a8f7eb4ce4c7c65f5df2aa',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 486.52381016423124,
    amountToken0: -486.764537,
    amountToken1: 0.2,
  },
  {
    hash: '0x959129ceb42e5e22ffee4a9a3b49c9f096e5343cfc192be22713d9f7eda6dab3',
    type: 0,
    timestamp: '1652077453',
    sender: '0x7727ba5bfc34bb821ca930cfa2dd3e3e6e4023d2',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1205.665426936673,
    amountToken0: -1206.260588,
    amountToken1: 0.495625,
  },
  {
    hash: '0x40fe8acee2e6c6d8126c391b2cc717025b9f63b023b25f989fbb7530d57ca444',
    type: 0,
    timestamp: '1652077453',
    sender: '0x292201e498a4c90bfe19d3fb14fbe54179984e97',
    token0Symbol: 'SHIB',
    token1Symbol: 'ETH',
    token0Address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 463.3041254924241,
    amountToken0: 26741584,
    amountToken1: -0.1905491436474597,
  },
  {
    hash: '0x27e178ef853762dfa00123e453b7c82c131d0dd855627c0fb70a164f381fccd5',
    type: 0,
    timestamp: '1652077453',
    sender: '0x7a0c9037fb4e51dd10a921e1ea8aaee07862f644',
    token0Symbol: 'APE',
    token1Symbol: 'ETH',
    token0Address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 16984.83247016936,
    amountToken0: 1536.2027666080273,
    amountToken1: -6.9855740627098335,
  },
  {
    hash: '0x27e178ef853762dfa00123e453b7c82c131d0dd855627c0fb70a164f381fccd5',
    type: 0,
    timestamp: '1652077453',
    sender: '0x7a0c9037fb4e51dd10a921e1ea8aaee07862f644',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 16992.416235084682,
    amountToken0: -17000,
    amountToken1: 6.9855740627098335,
  },
  {
    hash: '0x0deabbf2ddf5bc9f66290bf0b52b85cf5c2a05a707f1425783d8853c7f24e312',
    type: 0,
    timestamp: '1652077453',
    sender: '0x2ae9823384c914736fb83a694ea051b5d1d3c1c0',
    token0Symbol: 'stkAAVE',
    token1Symbol: 'AAVE',
    token0Address: '0x4da27a545c0c5b758a6ba100e3a049001de870f5',
    token1Address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
    amountUSD: 876.7876067321962,
    amountToken0: 7.374909803678015,
    amountToken1: -7.254861079300045,
  },
  {
    hash: '0x8f3a7886a83fddd74e1fc12a8110ff4c1df4c61ac24bcb1493dc86d5e6555883',
    type: 0,
    timestamp: '1652077445',
    sender: '0xf17fb29101b5acc670dc1f816917ebab5882f998',
    token0Symbol: 'X2Y2',
    token1Symbol: 'ETH',
    token0Address: '0x1e4ede388cbc9f4b5c79681b7f94d36a11abebc9',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 6434.497758602215,
    amountToken0: -26117.170050394965,
    amountToken1: 2.6464,
  },
  {
    hash: '0x89bcc9864586daad09f8bbc5bd561baf154aaedbc4cd2aa473965f7e613b43ce',
    type: 0,
    timestamp: '1652077445',
    sender: '0x4b479f5e351181689e8abfea3544a365c54c1ff7',
    token0Symbol: 'EURT',
    token1Symbol: 'USDT',
    token0Address: '0xc581b735a1688071a1746c968e0798d642ede491',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 55000,
    amountToken0: -52229.359979,
    amountToken1: 55000,
  },
  {
    hash: '0xd3c86888d83ece1d4f2a35acd6ce65b176ad937e05e3413daf4c934f832c4164',
    type: 0,
    timestamp: '1652077423',
    sender: '0xd4ef3fd15eef159b5e28b60d5893d8b754403c43',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 10448.578575845002,
    amountToken0: -10453.826276,
    amountToken1: 4.295165196456567,
  },
  {
    hash: '0xd3c86888d83ece1d4f2a35acd6ce65b176ad937e05e3413daf4c934f832c4164',
    type: 0,
    timestamp: '1652077423',
    sender: '0xd4ef3fd15eef159b5e28b60d5893d8b754403c43',
    token0Symbol: 'DAI',
    token1Symbol: 'USDC',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 10453.446218370154,
    amountToken0: -10453.066160740309,
    amountToken1: 10453.826276,
  },
  {
    hash: '0xd3c86888d83ece1d4f2a35acd6ce65b176ad937e05e3413daf4c934f832c4164',
    type: 0,
    timestamp: '1652077423',
    sender: '0xd4ef3fd15eef159b5e28b60d5893d8b754403c43',
    token0Symbol: 'LINK',
    token1Symbol: 'ETH',
    token0Address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 11018.96183638975,
    amountToken0: 1149.3193112074175,
    amountToken1: -4.52411607461495,
  },
  {
    hash: '0x8e6f9985200776dab55586d82dc5612aab8dded852ad513f9f9e695b45aa28f6',
    type: 0,
    timestamp: '1652077423',
    sender: '0x970c832394f724bc52cfa64c501e402d89319aab',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 13201.224388817436,
    amountToken0: -13208.067292,
    amountToken1: 5.426625740432208,
  },
  {
    hash: '0x87bd687af9011ddba8be23d3edc817c43d21092e491898d2f377e0a0614c2ed2',
    type: 0,
    timestamp: '1652077423',
    sender: '0x0f4ee9631f4be0a63756515141281a3e2b293bbe',
    token0Symbol: 'MATIC',
    token1Symbol: 'ETH',
    token0Address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 11673.8326558747,
    amountToken0: 12501.550759999996,
    amountToken1: -4.792669306134193,
  },
  {
    hash: '0x874fcb82fadb455de58ba98aeae4dc53376de673cbfb3f3453c626962ea59a6b',
    type: 0,
    timestamp: '1652077423',
    sender: '0xa83ca734c2c5970e41f524eec9c7b09d547d038d',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 27196.677044307227,
    amountToken0: -27212.092142,
    amountToken1: 11.17919289339322,
  },
  {
    hash: '0x874fcb82fadb455de58ba98aeae4dc53376de673cbfb3f3453c626962ea59a6b',
    type: 0,
    timestamp: '1652077423',
    sender: '0xa83ca734c2c5970e41f524eec9c7b09d547d038d',
    token0Symbol: 'TUSD',
    token1Symbol: 'USDC',
    token0Address: '0x0000000000085d4780b73119b644ae5ecd22b376',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 27207.303510867936,
    amountToken0: -27210.793361485223,
    amountToken1: 27212.092142,
  },
  {
    hash: '0x874fcb82fadb455de58ba98aeae4dc53376de673cbfb3f3453c626962ea59a6b',
    type: 0,
    timestamp: '1652077423',
    sender: '0xa83ca734c2c5970e41f524eec9c7b09d547d038d',
    token0Symbol: 'LINK',
    token1Symbol: 'ETH',
    token0Address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 28731.91830976999,
    amountToken0: 2987.26467940766,
    amountToken1: -11.792496749114433,
  },
  {
    hash: '0x86e1b13c4e158a809e7a2c6e850164a5ab0c00db6f4672f8ea3a8ea542bb9389',
    type: 0,
    timestamp: '1652077423',
    sender: '0x115d6885bfb84f0baf5c45a200a10d0a5f16ef03',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 43346.24871546015,
    amountToken0: 17.817677441869023,
    amountToken1: -43370.32181,
  },
  {
    hash: '0x6a9b5d8292767264ea195f2b2341e1ccd4f101e41ee4537d7ed77d744e4a88b1',
    type: 0,
    timestamp: '1652077423',
    sender: '0xd1e4a32679216f4a4dd38e45dab9bc4b8a45e592',
    token0Symbol: 'SPELL',
    token1Symbol: 'ETH',
    token0Address: '0x090185f2135308bad17527004364ebcc2d37e5f6',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 6625.839656669382,
    amountToken0: 2337443.8237535493,
    amountToken1: -2.7250956834926225,
  },
  {
    hash: '0x642a1b2528ad42fc0de940adbb5c2713456264e067fadd76d7cde37dbeba5338',
    type: 0,
    timestamp: '1652077423',
    sender: '0x76e40d0a69fd81826b5eb7d18145626d46eafdef',
    token0Symbol: 'ETH',
    token1Symbol: 'CRV',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xd533a949740bb3306d119cc777fa900ba034cd52',
    amountUSD: 15721.548456439714,
    amountToken0: -6.466006733703508,
    amountToken1: 7009.897360672573,
  },
  {
    type: 2,
    hash: '0x433e3dfb32994f53bdaebf9407087e23b16f9c281bae512ef950c488db324e57',
    timestamp: '1652077423',
    sender: '0xd730cd62cda9cfdc109be2d819b0337fafdca959',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 0,
    amountToken0: 0,
    amountToken1: 0,
  },
  {
    hash: '0x2a53e30b272f324e7aeeb6ba12cb75e6c29dc13fc74e6d136f80ad9fd774d22e',
    type: 0,
    timestamp: '1652077423',
    sender: '0xc9b69806b81427b66aeba62e35fa0979993bbb89',
    token0Symbol: 'SPELL',
    token1Symbol: 'ETH',
    token0Address: '0x090185f2135308bad17527004364ebcc2d37e5f6',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2576.9947717920672,
    amountToken0: -908026.251280725,
    amountToken1: 1.059874324294116,
  },
  {
    hash: '0x1d4ff65589e46f3c259c6d6993fbe40d52845458cde77be80dc0ce79ab2ae681',
    type: 0,
    timestamp: '1652077423',
    sender: '0xdd2300980430e0000000c0c6027ef7a0c93fc6bc',
    token0Symbol: 'ETH',
    token1Symbol: 'ADS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a',
    amountUSD: 2676.988373723171,
    amountToken0: 1.100999999999994,
    amountToken1: -710.32094410733,
  },
  {
    hash: '0x1d4ff65589e46f3c259c6d6993fbe40d52845458cde77be80dc0ce79ab2ae681',
    type: 0,
    timestamp: '1652077423',
    sender: '0xdd2300980430e0000000c0c6027ef7a0c93fc6bc',
    token0Symbol: 'ETH',
    token1Symbol: 'ADS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a',
    amountUSD: 2686.6679623235445,
    amountToken0: -1.104981050927828,
    amountToken1: 710.32094410733,
  },
  {
    hash: '0x1d4ff65589e46f3c259c6d6993fbe40d52845458cde77be80dc0ce79ab2ae681',
    type: 0,
    timestamp: '1652077423',
    sender: '0xdd2300980430e0000000c0c6027ef7a0c93fc6bc',
    token0Symbol: 'CIV',
    token1Symbol: 'USDT',
    token0Address: '0x37fe0f067fa808ffbdd12891c0858532cfe7361d',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 860.002517,
    amountToken0: -9034.207938324626,
    amountToken1: 860.002517,
  },
  {
    hash: '0x06abfc9c05997c5d962c475a5f32317b903b3bd44b4857cacbcee6aef1a469d7',
    type: 0,
    timestamp: '1652077423',
    sender: '0xa24ffe57c2873b86875000367cb855b112a37ac2',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 15354.874399174916,
    amountToken0: -15363.132397,
    amountToken1: 6.311803526582429,
  },
  {
    hash: '0x06abfc9c05997c5d962c475a5f32317b903b3bd44b4857cacbcee6aef1a469d7',
    type: 0,
    timestamp: '1652077423',
    sender: '0xa24ffe57c2873b86875000367cb855b112a37ac2',
    token0Symbol: 'LINK',
    token1Symbol: 'ETH',
    token0Address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 16202.474399142215,
    amountToken0: 1687.9868400809028,
    amountToken1: -6.65165722780079,
  },
  {
    hash: '0x81ec7ae202ec16b4279d9e1acc0bef8b9d20ad71ad94a2d8c0081632dce3a9f3',
    type: 0,
    timestamp: '1652077405',
    sender: '0xcc88036fa05c86129bf66260c2a905ab2c7b663c',
    token0Symbol: 'gOHM',
    token1Symbol: 'USDC',
    token0Address: '0x0ab87046fbb341d058f17cbc4c1133f25a20a52f',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 4041.857145,
    amountToken0: 1.8565,
    amountToken1: -4041.857145,
  },
  {
    hash: '0x733bcb8af4e334a287a9e4a47191e97fb7d58ed05cae6bd03432ff0da5d515f6',
    type: 0,
    timestamp: '1652077405',
    sender: '0x0d5a17fb4a430512a516f6c633cc8e7a3a351d79',
    token0Symbol: 'ETH',
    token1Symbol: 'GTC',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xde30da39c46104798bb5aa3fe8b9e0e1f348163f',
    amountUSD: 1622.2016706736053,
    amountToken0: -0.6671840852740011,
    amountToken1: 389.3719806763285,
  },
  {
    hash: '0x63e4ce4bbd14ed21a47b3b6bc3bd12a4b531be71b4475effc8466fcddae5815e',
    type: 0,
    timestamp: '1652077405',
    sender: '0xf6aef1044028907c86eb8581b1671e924392d3b4',
    token0Symbol: 'LUSD',
    token1Symbol: 'ETH',
    token0Address: '0x5f98805a4e8be255a32880fdec7f6728c6568ba0',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 12157.077083211561,
    amountToken0: -12139.42944241917,
    amountToken1: 5,
  },
  {
    hash: '0x593a5c7efb6a011a8a344b65bfc9fa99dcacee69039b12673fdc03d2e6b92433',
    type: 0,
    timestamp: '1652077405',
    sender: '0x2c25c3cc61b9a0aa0aa8b5a17dabbd990a696cbe',
    token0Symbol: 'LDO',
    token1Symbol: 'ETH',
    token0Address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2.4314154166423125,
    amountToken0: -0.8901340884401835,
    amountToken1: 0.001,
  },
  {
    hash: '0x3ef57d55243decc21a2287ff8b30550998f93d7101f049ec1cb20337a36dbfd4',
    type: 0,
    timestamp: '1652077405',
    sender: '0xad35e148c13ebb634e305c0979a8b18d8d897dc3',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 21652.59055455829,
    amountToken0: -21665.583901,
    amountToken1: 8.9,
  },
  {
    hash: '0x3ef57d55243decc21a2287ff8b30550998f93d7101f049ec1cb20337a36dbfd4',
    type: 0,
    timestamp: '1652077405',
    sender: '0xad35e148c13ebb634e305c0979a8b18d8d897dc3',
    token0Symbol: 'USDC',
    token1Symbol: 'USDT',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 21666.3282665,
    amountToken0: 21665.583901,
    amountToken1: -21667.072632,
  },
  {
    hash: '0x12e2aeb4645c8fe8fcad28ba9c52415d90db1cc119a760053cf113d50fe7c37c',
    type: 0,
    timestamp: '1652077405',
    sender: '0x16b62518c544b16d03738eccdc1a4070427853d5',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 85.89308138221969,
    amountToken0: -0.03528239649105567,
    amountToken1: 86,
  },
  {
    hash: '0xec031419b999edc148ba5a7b7c907784c3c21cc76c5735715cc2c7ea3af3fc9d',
    type: 0,
    timestamp: '1652077399',
    sender: '0x76e40d0a69fd81826b5eb7d18145626d46eafdef',
    token0Symbol: 'OGN',
    token1Symbol: 'ETH',
    token0Address: '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 5076.121092451046,
    amountToken0: 14831.216983330485,
    amountToken1: -2.0877226728540554,
  },
  {
    hash: '0xf495096cf654023753b9ef01a050c185dcfe2a3863a2adb9366eeec2c65a05a3',
    type: 0,
    timestamp: '1652077391',
    sender: '0x26bce6ecb5b10138e4bf14ac0ffcc8727fef3b2e',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 246120.69014115306,
    amountToken0: -246305.605701,
    amountToken1: 101.149220695875,
  },
  {
    hash: '0xaf2cff8bffe9ffd4271c4e82b26123bf25c9afc4550a9c57f71171d4d7873381',
    type: 0,
    timestamp: '1652077391',
    sender: '0x5aa3393e361c2eb342408559309b3e873cd876d6',
    token0Symbol: 'ETH',
    token1Symbol: 'TRU',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf65b5c5104c4fafd4b709d9d60a185eae063276c',
    amountUSD: 3054.5941741900065,
    amountToken0: 1.2563028733314026,
    amountToken1: -19638.097081627468,
  },
  {
    hash: '0x15cbceb8a0dc34f5c258b9f97c08bdabd3ffd9037d6d21ce90d462fdfd2bcf14',
    type: 0,
    timestamp: '1652077391',
    sender: '0xe3ff9c2d8349d18489ae48a4da91368a99a0ac50',
    token0Symbol: 'USDC',
    token1Symbol: 'YLD',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xf94b5c5651c888d928439ab6514b93944eee6f48',
    amountUSD: 6000,
    amountToken0: 6000,
    amountToken1: -30797.19929573964,
  },
  {
    hash: '0xbe0d006b273b02c65743219a5a8d13c0ab11ebc653ec1a63f10b5313f472b21e',
    type: 0,
    timestamp: '1652077376',
    sender: '0xcaa4a720cdfefb765ce9f42046b4a64dbeb1f018',
    token0Symbol: 'NFT',
    token1Symbol: 'ETH',
    token0Address: '0x198d14f2ad9ce69e76ea330b374de4957c3f850a',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 303.92692708028903,
    amountToken0: -241659525.888735,
    amountToken1: 0.125,
  },
  {
    type: 1,
    hash: '0x4a9ac2fdaaa9aca9be978e1a990a7e6b17a201f8dcd9249955ac02486ddbd040',
    timestamp: '1652077376',
    sender: '0x9115b05c877ac8f2f8c02aeaea35f58427790e66',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 9961.319351542294,
    amountToken0: 5000,
    amountToken1: 2.0405066602702044,
  },
  {
    hash: '0x454ff9f76a23c427f8276604b3b7ab8226dbdb62aba2375b30caf0a64a79d8f8',
    type: 0,
    timestamp: '1652077376',
    sender: '0x2c177735792af1f07b37fc44fb875c18be3fb4fc',
    token0Symbol: 'USDC',
    token1Symbol: 'USDT',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 24383.8199505,
    amountToken0: -24380.842852,
    amountToken1: 24386.797049,
  },
  {
    hash: '0x454ff9f76a23c427f8276604b3b7ab8226dbdb62aba2375b30caf0a64a79d8f8',
    type: 0,
    timestamp: '1652077376',
    sender: '0x2c177735792af1f07b37fc44fb875c18be3fb4fc',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 24347.49850921156,
    amountToken0: 24380.842852,
    amountToken1: -10,
  },
  {
    hash: '0x4c22bbee8e7c5e66e7c92306f5d60b2ed9b0f9918c74c98bfbf32b2e00705df9',
    type: 0,
    timestamp: '1652077373',
    sender: '0xf07704777d6bc182bf2c67fbda48913169b84983',
    token0Symbol: 'ETH',
    token1Symbol: 'COTI',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xddb3422497e61e13543bea06989c0789117555c5',
    amountUSD: 2173.266222677071,
    amountToken0: 0.8938276066696429,
    amountToken1: -12563.595155903995,
  },
  {
    hash: '0x8fe1b6a14dfd889542453f4d916825f56932e4f0022f1f267a0c8a8df0b57c04',
    type: 0,
    timestamp: '1652077364',
    sender: '0xd285603cfa2e5a5c2eddd97d80cc0e22ae20c084',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 413865.94861091964,
    amountToken0: 414317.301607,
    amountToken1: -170.03042457703438,
  },
  {
    hash: '0x8fe1b6a14dfd889542453f4d916825f56932e4f0022f1f267a0c8a8df0b57c04',
    type: 0,
    timestamp: '1652077364',
    sender: '0xd285603cfa2e5a5c2eddd97d80cc0e22ae20c084',
    token0Symbol: 'DAI',
    token1Symbol: 'ETH',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 37622.558416795946,
    amountToken0: 37651.809075066056,
    amountToken1: -15.461491072735193,
  },
  {
    hash: '0x8fe1b6a14dfd889542453f4d916825f56932e4f0022f1f267a0c8a8df0b57c04',
    type: 0,
    timestamp: '1652077364',
    sender: '0xd285603cfa2e5a5c2eddd97d80cc0e22ae20c084',
    token0Symbol: 'DAI',
    token1Symbol: 'USDC',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 37653.177505033025,
    amountToken0: -37651.809075066056,
    amountToken1: 37654.545935,
  },
  {
    type: 1,
    hash: '0x7b9a04339896cc2bc67227b51c32da621976731069f067f64c70e0d131ff3a78',
    timestamp: '1652077364',
    sender: '0x166349fe51c73148c3f140ba06b4d7867c985806',
    token0Symbol: 'MAXI',
    token1Symbol: 'HEX',
    token0Address: '0x0d86eb9f43c57f6ff3bc9e23d8f9d82503f0e84b',
    token1Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    amountUSD: 1336.0024102792308,
    amountToken0: 5462.01255363,
    amountToken1: 8000,
  },
  {
    hash: '0x35487a0a5028d7b89b53e4df39597fffa51eef78afac2acb3679798e9ee72382',
    type: 0,
    timestamp: '1652077364',
    sender: '0x0f2dcaaeb6e3e1bff243fd44a4bfaddb28de373e',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 24346.867735211563,
    amountToken0: 24379.581304,
    amountToken1: -10,
  },
  {
    hash: '0x0e38549a03a97d92cfaa8a76a0f4351d67a9f0f5eb4226dee1f0b2f0e3f77324',
    type: 0,
    timestamp: '1652077364',
    sender: '0xe6a1f65e6e9de17c164181123627024f136a25ef',
    token0Symbol: 'ETH',
    token1Symbol: 'ADS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a',
    amountUSD: 1206.2498986711305,
    amountToken0: 0.496110163,
    amountToken1: -316.39096684966,
  },
  {
    hash: '0xe3060857d35cc6b104eecd231f79ff51ddb37e75347e455c6a667da9830766c6',
    type: 0,
    timestamp: '1652077341',
    sender: '0x7a380d1c185a520ae1ab29707bdbbe2fb081488d',
    token0Symbol: 'ETH',
    token1Symbol: 'LOOKS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    amountUSD: 2186.0304380621337,
    amountToken0: 0.8990773123750916,
    amountToken1: -1823.210605788787,
  },
  {
    hash: '0xc56f20c8da7c1489ddb4af78861fc35de54d1b3c74effaa2814c7df328c5979f',
    type: 0,
    timestamp: '1652077341',
    sender: '0xe58e5ed4544f58831a84c18178b911a5957ada08',
    token0Symbol: 'WBTC',
    token1Symbol: 'USDC',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 27162.725732178755,
    amountToken0: -0.81452517,
    amountToken1: 27193.76815,
  },
  {
    hash: '0xbe58bd300c1f04fdf3e4854bdffa82e872d1a7bae849124681f71bc32dd1284a',
    type: 0,
    timestamp: '1652077341',
    sender: '0xb4b412a5f575076bdec204d6c7f551ec256aabb2',
    token0Symbol: 'HEX',
    token1Symbol: 'USDC',
    token0Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 5000,
    amountToken0: -29826.23493023,
    amountToken1: 5000,
  },
  {
    hash: '0xb8f9f67dc9f705587421b62d400459c7d801b256a2f27ac10b2b5e37243409c3',
    type: 0,
    timestamp: '1652077341',
    sender: '0xdc447ec88c5f092ea2767f127e3fe0e569384f6b',
    token0Symbol: 'MET',
    token1Symbol: 'ETH',
    token0Address: '0x1ffe8a8177d3c261600a8bd8080d424d64b7fbc2',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 275.1372710652416,
    amountToken0: 9274,
    amountToken1: -0.11315930185438865,
  },
  {
    hash: '0x8fc65dde39863487ab426307c21f0631cc38aad8cf401a572f842b484223d28c',
    type: 0,
    timestamp: '1652077341',
    sender: '0x26bce6ecb5b10138e4bf14ac0ffcc8727fef3b2e',
    token0Symbol: 'APE',
    token1Symbol: 'USDC',
    token0Address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 8410.957043,
    amountToken0: -757.0246192070541,
    amountToken1: 8410.957043,
  },
  {
    hash: '0x7dfe8cb65b1c3c95d83d8f736bfa4156e1bd4f2437f656db3d0e0354cb22a7cf',
    type: 0,
    timestamp: '1652077341',
    sender: '0x391a68adfac35b2e664b4ff5868aef26375a23a5',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 9485.647379952508,
    amountToken0: -9488.774635,
    amountToken1: 3.9,
  },
  {
    hash: '0x7667dfd9d0457a9323287a29d26613b5679a303b6973dca39e888103729560c5',
    type: 0,
    timestamp: '1652077341',
    sender: '0xc9e187620e09c7207910597120c58895555e5aff',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2377.39143257326,
    amountToken0: 2379,
    amountToken1: -0.9771192733602805,
  },
  {
    hash: '0x7667dfd9d0457a9323287a29d26613b5679a303b6973dca39e888103729560c5',
    type: 0,
    timestamp: '1652077341',
    sender: '0xc9e187620e09c7207910597120c58895555e5aff',
    token0Symbol: 'ETH',
    token1Symbol: 'LOOKS',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    amountUSD: 2375.7828651465197,
    amountToken0: 0.9771192733602805,
    amountToken1: -1981.6984971792645,
  },
  {
    hash: '0x67c22819baf6611e9dd6d2d5e8f8f72524fcce97eb3159777895e1696b944e66',
    type: 0,
    timestamp: '1652077341',
    sender: '0x730370447eb0c363fa9cd2cbcedbd27e3bd32edd',
    token0Symbol: 'MET',
    token1Symbol: 'ETH',
    token0Address: '0x1ffe8a8177d3c261600a8bd8080d424d64b7fbc2',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1514.8931402381859,
    amountToken0: 51201.4330508239,
    amountToken1: -0.623049903307018,
  },
  {
    hash: '0x573dbc1278c4e3718aea56a1bfbb06abe50e44a7305aa0f3b224db839b61c1b2',
    type: 0,
    timestamp: '1652077341',
    sender: '0x431b5a84acc1297eda88259f300262f1bc3a74f3',
    token0Symbol: 'APE',
    token1Symbol: 'ETH',
    token0Address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 52087.87145424122,
    amountToken0: -4685.664349910155,
    amountToken1: 21.42285974569187,
  },
  {
    hash: '0x56e08c8ad5d5364db034c2b72f1511ba763862cbc3ae7cae03be9d9b8ff4a935',
    type: 0,
    timestamp: '1652077341',
    sender: '0x61eb9739dfd15df7623a492c9b8f822608483b3c',
    token0Symbol: 'MET',
    token1Symbol: 'ETH',
    token0Address: '0x1ffe8a8177d3c261600a8bd8080d424d64b7fbc2',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 284.9296205101015,
    amountToken0: 9595.902020283565,
    amountToken1: -0.11718672940865774,
  },
  {
    hash: '0x3f9d2b4c03050119cde47f41bed662033ff4cd479c0a2f24b70e3c51a034d2e5',
    type: 0,
    timestamp: '1652077341',
    sender: '0xcd7ff04ca911aef8ee13393b285ad06e4822d496',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 194.6760096656925,
    amountToken0: 194.838786,
    amountToken1: -0.08,
  },
  {
    hash: '0x3a379c8002d23dc2e559f738ab5823bdcd3b1ec6e7c23b52f753ff341c610032',
    type: 0,
    timestamp: '1652077341',
    sender: '0xa5a13f62ce1113838e0d9b4559b8caf5f76463c0',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 115786.35742708239,
    amountToken0: 115874.021318,
    amountToken1: -47.58491401520356,
  },
  {
    hash: '0x2a075b04c43e39efb44af9103391ce48c23e6862aef46d18beab7d78db854d94',
    type: 0,
    timestamp: '1652077341',
    sender: '0x6046945c5b5ef5933b8e73a98a6ad7bf3e031df7',
    token0Symbol: 'WBTC',
    token1Symbol: 'ETH',
    token0Address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 125708.8317472829,
    amountToken0: -3.77140199,
    amountToken1: 51.73651418416668,
  },
  {
    hash: '0x203984fe6b2c78240ee6eb8556af7d20552c63cdb14bd35f1c84c693aad9fb60',
    type: 0,
    timestamp: '1652077341',
    sender: '0x5714bd32195126eaeb3b5b5265420f0ea7886c79',
    token0Symbol: 'HEX',
    token1Symbol: 'ETH',
    token0Address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 1458.8492499853874,
    amountToken0: -8690.65487845,
    amountToken1: 0.6,
  },
  {
    hash: '0x143eb6817400402ec024154a7318464c371b14bf75e70f9f5aafc636690a3460',
    type: 0,
    timestamp: '1652077341',
    sender: '0xf10bf3f40bb79a502e0017d007e41777905e5281',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 2432.197520821156,
    amountToken0: -2432.979625,
    amountToken1: 1,
  },
  {
    hash: '0x054ac3fc0110c51239ef8fe247831974cb6370ab47cb7fe007a23299f2770a3b',
    type: 0,
    timestamp: '1652077341',
    sender: '0x2c177735792af1f07b37fc44fb875c18be3fb4fc',
    token0Symbol: 'ETH',
    token1Symbol: 'USDT',
    token0Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 24341.852722211563,
    amountToken0: -10,
    amountToken1: 24369.551278,
  },
  {
    hash: '0xfe6b65da8d658948e73840fb0c6770ff86b8c0c48923030ba44e8c89ea05017c',
    type: 0,
    timestamp: '1652077308',
    sender: '0x085ee206a33d7958b0f7ff464ff3fe096e868976',
    token0Symbol: 'USDC',
    token1Symbol: 'ETH',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    amountUSD: 972.7929838284625,
    amountToken0: -973.019801,
    amountToken1: 0.4,
  },
  {
    hash: '0xeb43bcbb3919f78bb8d87958b64a40c4b2c0e457b24354e7ea7085d1bd169908',
    type: 0,
    timestamp: '1652077308',
    sender: '0xd4a08cf067c83d1b2cc1d26831569b7850804be7',
    token0Symbol: 'USDC',
    token1Symbol: 'USDT',
    token0Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token1Address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amountUSD: 5000.172062,
    amountToken0: 5000,
    amountToken1: -5000.344124,
  },
  {
    type: 2,
    hash: '0xe3d9da59c11eb73f7c0e72c9be1fa31d22b12c030024caee0eb4d53ded07dcdc',
    timestamp: '1652077308',
    sender: '0x64f8e38e19c1b6992969dcd5769c149951018af1',
    token0Symbol: 'DAI',
    token1Symbol: 'USDC',
    token0Address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    token1Address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    amountUSD: 228196.8917021206,
    amountToken0: 131206.12700512062,
    amountToken1: 96990.764697,
  },
];
